    <!-- myBagCont -->
    <div class="myBagCont">
        <!-- <h5>Share + get insights for your club bag</h5> -->
        <div class="progressBg" *ngIf="step < 4">
            <label>Step {{step}} of 3</label>
            <div class="progress">
                <div class="progress-bar" role="progressbar" [style]="step==1? 'width: 33.3%' : (step ==2 ?'width: 66.6%' :'width: 100%')" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
          <!-- handicapBlock -->
          <div class="handicapBlock" *ngIf="step == 1">
            <p>Hey there, {{user?.name}}. I’m your <span>Golf.ai</span> partner. here to help you dial in the perfect set of clubs. First things first - let's get to know you a bit better so I can compare your game to other players like you. Don't worry, this'll be quick and easy!</p>
            <div class="handicapBlock__box">
                <div class="dropdown">
                    <label>What's your age range?</label>
                    <button class="selectBox dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                       {{selectedAge}}
                    </button>
                    <ul class="dropdown-menu">
                      <li *ngFor="let age of ageList; let i = index" (click)="selectedAge= age">{{age}}</li>
                    </ul>
                </div>
                <div class="dropdown">
                    <label>Your gender?</label>
                    <button class="selectBox dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{selectedGender}}
                    </button>
                    <ul class="dropdown-menu">
                      <li *ngFor="let gender of genderList" (click)="selectedGender = gender">{{gender}}</li>
                    </ul>
                </div>
                <div class="dropdown">
                    <label>What's your current handicap?</label>
                    <button class="selectBox dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                       {{selectedHandicap}}
                    </button>
                    <ul class="dropdown-menu">
                      <li *ngFor="let handicap of handicapList" (click)="selectedHandicap= handicap">{{handicap}}</li>
                    </ul>
                </div>
                <div class="dropdown">
                    <label>How frequently do you hit the links?</label>
                    <button class="selectBox dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                       {{selectedRoundPerYear}}
                    </button>
                    <ul class="dropdown-menu">
                      <li *ngFor="let frequency of hittingFrequency" (click)="selectedRoundPerYear= frequency">{{frequency}}</li>
                    </ul>
                </div>
                <div class="dropdown">
                    <label>Do you prefer yards or meters?</label>
                    <button class="selectBox dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{selectedUnit}}
                    </button>
                    <ul class="dropdown-menu">
                      <li *ngFor="let unit of units" (click)="selectedUnit= unit">{{unit}}</li>
                    </ul>
                </div>
                <a href="javascript:void(0)" (click)="nextStep()" class="common-btn">Continue</a>
            </div>
        </div>
        <!-- handicapBlock -->
          <!-- handicapBlock -->
          <div class="handicapBlock lg" *ngIf="step== 2">
            <span role="button" class="d-inline-block cursor-pointer goBack">
                <a (click)="step=1">
                    <- Go back </a>
            </span>
            <p>Awesome, now we're getting somewhere! Let's talk about your club distances. This helps me figure out if your current setup is maximizing your potential.
                I've put in some averages based on what you told me earlier, but feel free to adjust if your drives go to the moon or your wedges have magical precision. Don't be shy - we're here to improve your game!</p>
            <div class="handicapBlock__box">
                <div class="row dtlsBox">
                    <div class="col-6" *ngFor="let clubDistance of clubDistanceList;let i = index;">
                        <div class="d-flex">
                            <span *ngIf="i % 2 == 0">{{clubDistance.club}}</span> 
                            <div class="d-inline-flex"><input type="text" [(ngModel)]="clubDistanceList[i].distance" [name]="i+'_distance'"></div>
                            <span *ngIf="i % 2 != 0">{{clubDistance.club}}</span> 
                            <!-- <span>{{clubDistance.club}}</span>  -->
                        </div>
                    </div>
                </div>
                <a href="javascript:void(0)" (click)="nextStep()" class="common-btn mt-4">Continue</a>
            </div>
        </div>
        <!-- handicapBlock -->
         <ng-container *ngIf="step == 3">
            <span role="button" class="d-inline-block cursor-pointer goBack">
                <a *ngIf="!isProcessing" (click)="step = 2">
                    <- Go back </a>
            </span>
            <!-- textArea -->
            <div class="textArea">
                <p class="points">Final step: Show us what's in your golf bag!<br>
                    Create a short video of your golf bag, highlighting each club:</p>
                <div class="points">
                    1. Film each club clearly<br>
                    2. Say the club type, brand, and model out loud<br>
                    3. Keep it under 3 minutes
                </div>
                <p class="points">Our AI will analyze your equipment to offer personalized insights and recommendations.</p>
            </div>
            <!-- textArea -->
        <div class="fileUploadBg mt-0" *ngIf="!file?.preview">
            <div class="fileUpload">
                <div class="d-block">
                    <div class="d-flex justify-content-around mb-1">
                        <div class="d-flex align-items-center">
                            <span>
                                <a>
                                    <input #fileInput type="file" (change)="onFileChange($event)" accept="video/*">
                                    @if (!base64Processing){
                                      <img src="assets/images/common/image_icon.svg" alt="">
                                    } @else {
                                      <img class="media-process" src="assets/images/send-loader.svg" alt="">
                                    }
                                </a>
                            </span>
                            @if (!base64Processing){
                                <label>{{'Attach Video' | translate}}</label>
                              } @else {
                                <label>{{'Processing Media...' | translate}}</label>
                              }
                        </div>
                        <!-- <div class="d-flex flex-column align-items-center" (click)="openCameraModal(cameraModal)">
                            <span>
                                <img src="assets/images/common/camera-icon.svg" alt="">
                            </span>
                            <label>Camera</label>
                        </div> -->
                    </div>
                    <div class="title">Film and upload your clubs</div>
                    <p>5 minutes max</p>
                </div>
            </div>
        </div>
        <div class="fileUploadBg" *ngIf="file?.preview">
            <div class="fileUpload">
                <div class="d-block">
                    <div class="title mb-3">
                        @if (isProcessing){
                            {{'BagUploadingProgress' | translate}} <br>
                            {{'bagUploadingText' | translate}}
                        } @else {
                            {{'BagUploadedSuccessfully'| translate}}
                        }
                    </div>
                    <div class="d-flex justify-content-around mb-1">
                        <img *ngIf="isProcessing" class="btn-img" src="/assets/images/loading-btn.svg" />
                        <ng-container *ngIf="!isProcessing">
                            <div class="d-flex flex-column align-items-center" (click)="removeFile()">
                                <span>
                                    <a>
                                        <img src="assets/images/common/refresh.svg" alt="">
                                    </a>
                                </span>
                                <label>Redo</label>
                            </div>
                            <div class="d-flex flex-column align-items-center" (click)="submit()">
                                <span>
                                    <img src="assets/images/textareaIcon4.svg" alt="">
                                </span>
                                <label>Send</label>
                            </div>
                            </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!-- howWorksBlck -->
        <div class="howWorksBlck" *ngIf="!isProcessing">
            <h6>{{'HowItWorks' | translate}}</h6>
            <div class="row">
              <div class="col-md-6 order-md-2 position-relative" [class.video]="videoPlaying">
                 
                 <button class="play-btn" (click)="playVideo(explainerVideo)">{{'Watch explainer' | translate}}</button>
                  <img src="assets/images/bag-video-img.png" alt="">
              </div>
              <div class="col-md-6 mt-3 mt-md-0 hw-desc">
                <p class="mb-4">
                  {{'HowItWorksDesc' | translate}}
                </p>
                <p>
                  {{'HowItWorksDescSecond' | translate}}
                </p>
              </div>
            </div>
          </div>
        <!-- howWorksBlck -->
    </ng-container>
    <!-- myBagCont -->
      <!-- textBlock -->
      <div class="textBlock" *ngIf="successfullySubmitted">
          <!-- textBlock -->
            <p>We're analyzing your gear to provide personalized insights. What's next?</p>
            <p class="mt-4">Check your email soon for a detailed analysis from your <span>Golf.ai</span> Playing Partner.</p>
            <p class="mt-4">Continue your golf journey: Chat with your <span>Golf.ai</span> partner about your equipment, technique, rules, or any golf topic.</p>
            <a href="javascript:void(0)" routerLink="/home" class="golf mt-4">Golf.ai</a>
            <p class="text-center my-3">OR</p>
            <div class="d-block">
                <p>Explore the latest golf news, tips, and stories on Golf Digest.</p>
                <p class="mt-3">Your golf adventure is just beginning</p>
            </div>
            <a href="javascript:void(0)" target="_blank" href="https://www.australiangolfdigest.com.au/" class="mt-4">Golf Digest</a>
        <!-- textBlock -->
    </div>
    <!-- textBlock -->
    </div>
    <ng-template #cameraModal>
        <app-media-modal (capturedContent)="onCameraCaptured($event)"
            (closeModal)="closeCameraModal($event)"></app-media-modal>
    </ng-template>
    <ng-template #explainerVideo>
        <div class="iframe-wrap">
          <button (click)="closeExplainerModal($event)">&times;</button>
          <iframe 
            [src]="_sanitizer.bypassSecurityTrustResourceUrl(videoUrl)" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen>
          </iframe>
          <!-- Coming soon... -->
        </div>
      </ng-template>