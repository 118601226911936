import { CommonModule, isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MyBagModel } from "../model/my-bag.model";
import { ToasterService } from "../shared/services/toaster.service";
import { MediaModalComponent } from "../shared/components/media-modal/media-modal.component";
import { MediaService } from "../services/media.service";
import { Subject, Subscription, switchMap, takeUntil } from "rxjs";
import { ChatService } from "../services/chat/chat.service";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { TranslatePipe } from "../pipes/translate.pipe";
import { Constants } from "../utils/constants";
import { HandicapData } from "../model/handicap.model";
import { UserSettingService } from "../services/user-setting.service";
import { User } from "../model/user.model";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-golf-digest",
  standalone: true,
  imports: [CommonModule, MediaModalComponent, ReactiveFormsModule, FormsModule,RouterModule, TranslatePipe],
  providers:[TranslatePipe],
  templateUrl: "./golf-digest.component.html",
  styleUrl: "./golf-digest.component.scss"
})
export class GolfDigestComponent implements OnInit, OnDestroy{
  myBagDatas: MyBagModel[] = [];
  loadingArray= new Array(4);
  file: any;
  cameraModalRef: NgbModalRef | undefined;
  selectedFile: File | null = null;
  fileSizeInMb = 0;
  @ViewChild("videoPlayer") videoElement: any;
  mediaUrl: string = "";
  isProcessing = false;
  noResultFound= "";
  step = 1;
  selectedHandicap= "Less than 5";
  selectedRoundPerYear= "Rarely: 1-5 times per year";
  private destroy$ = new Subject<void>();
  ageList: string[] = [
    "18-25",
    "26-35",
    "36-45",
    "46-55",
    "56-65",
    "66+"
  ];
  selectedAge= "18-25";
  genderList:string[]=[
    "Male",
    "Female",
    "Prefer not to say"
  ];
  selectedGender= "Male";
  handicapList: string[]=[
    "Less than 5",
    "5-10",
    "10-15",
    "15-20",
    "20+",
    "I don't know my handicap"
  ];
  hittingFrequency: string[]=[
    "Rarely: 1-5 times per year",
    "Occasionally: 6-12 times per year",
    "Regularly: 13-29 times per year",
    "Addicted: 30+ times per year"
  ];
  units:string[]=["Yards","Meters"];
  selectedUnit= "Yards";
  clubDistanceList: HandicapData[]=[];
  selectedClub= "";
  selectedDistance = 0;
  successfullySubmitted = false;
  private _subscription: Subscription = new Subscription();
  hasError= false;
  user: User | null = new User();
  base64Processing = false;
  fileBlob: any;
  explainerModalRef: NgbModalRef | undefined;
  videoPlaying = false;
  videoUrl: string = 'https://www.youtube.com/embed/0GCHHoGE7u4?autoplay=1&playsinline=1&color=white&modestbranding=1&rel=0';
  constructor(
    private _toaster: ToasterService,
    private _mediaService: MediaService,
    private _modalService: NgbModal,
    private _chatService: ChatService,
    private _fb: FormBuilder,
    private _router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private _translatePipe: TranslatePipe,
    private _userSettingService: UserSettingService,
    public _sanitizer: DomSanitizer
  ){

  }

  ngOnInit(): void {
    this.getUserSetting();
    // subscribe bag respone
    this._subscription.add(this._chatService.getMyBagResponse()
   .pipe(takeUntil(this.destroy$))
   .subscribe((res) => {
      this.isProcessing = false;
      this.successfullySubmitted = true;
      this.step++;
    }));
    this._subscription.add(
      this._chatService.getMyBagError().subscribe((res) => {
        this.isProcessing = false;
        this.hasError = true;
        this.file= null;
        this._toaster.error(res,"Error");
      })
    );
  }
  nextStep(){
    if(this.step ==1){
      let clubDistanceResult = this.getClubDistances(this.selectedGender, this.selectedHandicap, this.selectedUnit);
      this.clubDistanceList = clubDistanceResult ? clubDistanceResult : [];    
    }
    // else if(this.step == 2 &&(!this.selectedClub && !this.selectedDistance))
    // {
    //   this._toaster.warning("Please select your club distance.")
    //   return;
    // }  
    this.step++;
  }
  previousStep(){
    this.step--;
  }
  onFileChange(event: any) {
    const fileInput = event.target;
    if (fileInput.files.length < 0) {
      return this._toaster.warning(
        this._translatePipe.transform('FileRequired'), this._translatePipe.transform("Warning")
      );
    }
    if (!fileInput.files[0].type.includes('video')) {
      fileInput.value = '';
      return this._toaster.warning(
        this._translatePipe.transform('VideoValidation'), this._translatePipe.transform("Warning")
      );
    }
    this.selectedFile = fileInput.files[0];
    this.fileSizeInMb = this.selectedFile!.size / (1024 * 1024); // Convert file size to MB
    if (this.fileSizeInMb > 300) {
      fileInput.value = '';
      return this._toaster.error(
        this._translatePipe.transform('VideoSizeValidation'), this._translatePipe.transform("Error")
      );
    }
    // Preview the file
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.file = {
        name: this.selectedFile!.name,
        type: this.selectedFile!.type,
        size: this.selectedFile!.size,
        preview: e.target.result,
        file: this.selectedFile,
      };
      this.fileBlob = this.base64ToBlob(e.target.result, this.selectedFile!.type);
      this.base64Processing = false;
    };
    if (this.selectedFile) {
      this.base64Processing = true;
      reader.readAsDataURL(this.selectedFile);
    }
  }
  onCameraCaptured(photo: {
    name: string;
    type: string;
    size: number;
    preview: string;
    file: File;
  }): void {
    this.file = {
      name: photo.name,
      type: photo.type,
      size: photo.size,
      preview: photo.preview,
      file: photo,
    };
    this.cameraModalRef?.dismiss();
  }
  closeExplainerModal(event: any) {
    this.explainerModalRef?.dismiss();
  }
  openCameraModal(content: any) {
    this.cameraModalRef = this._modalService.open(content, {
      backdrop: "static",
      centered: true,
    });
  }
  closeCameraModal(event: any) {
    this.cameraModalRef?.dismiss();
  }
  removeFile() {
    this.file = null;
  }
  onMetadata(e: any, video: any) {
    console.log("duration: ", video.duration);
  }
  submit() {
     if (!this.file) {
        return this._toaster.error("Please upload file first.","Error");
      }
      if (this.file.type.startsWith("video/")) {
        let docId = document.getElementById("myBagVideo");
        // const video = this.videoElement.nativeElement;
        const duration = 0;
        if (this.fileSizeInMb > 300) {
          return this._translatePipe.transform('VideoSizeValidation'), this._translatePipe.transform("Error")
        }
      }
      if (!this.file.type.startsWith("video/")) {
        return this._translatePipe.transform('VideoSizeValidation'), this._translatePipe.transform("Error")
      }
    this.noResultFound = "";
    this.isProcessing = true;
    let blob;
    if (this.fileBlob){
      blob = this.fileBlob;
    } else {
      blob = this.base64ToBlob(this.file.preview, this.file.type);
    }
   this._mediaService
      .getMediaUrls(this.file.name)
      .pipe(
        switchMap((response1) => {
          this.mediaUrl = response1[0].get?.url!;
          return this._mediaService.uploadMediaToS3(response1[0].put?.url!, blob);
        })
      )
      .subscribe({
        next: (res) => {
          this._chatService
          .onListenWebsocket()
          .then(() => {
            this._chatService.uploadGolfDigestMyBag(this.mediaUrl, this.selectedHandicap, this.selectedRoundPerYear, this.selectedAge, this.selectedGender, this.selectedUnit,this.clubDistanceList);
            this.successfullySubmitted = true;
            this.step = 4;
          })
          .catch((error) => {
            this._toaster.error(this._translatePipe.transform("SocketConnectionError"),"Error");
            // console.error("WebSocket Connection error:", error);
          });
        },
        error: (err) => {
          this.isProcessing= false;
        },
      });
  }
  // Method to convert Base64 to Blob
  base64ToBlob(base64: string, contentType: string): Blob | null {
    try {
      const base64Data = base64.includes(',') ? base64.split(',')[1] : base64;
      const byteCharacters = atob(base64Data);
      const chunkSize = 1024 * 1024; // Process in 1 MB chunks
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += chunkSize) {
        const slice = byteCharacters.slice(offset, offset + chunkSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
      }

      return new Blob(byteArrays, { type: contentType });
    } catch (error) {
      console.error("Error converting base64 to Blob:", error);
      return null;
    }
  }
  getClubDistances(gender: string, handicap:string, unit: string): HandicapData[] | undefined{
    const distanceList= gender =='Female' ? Constants.GolfDistance.womenDistances : Constants.GolfDistance.menDistances;
    let handiCapRange= handicap;
    if(handicap == "i don't know my handicap"){
      handiCapRange = "15-20";
    }
    const handicapData= distanceList.find((d: any) => d.handicapRange === handiCapRange);
    let result = handicapData?.distances.map((club: any) => ({
      club: club.club,
      distance: club[unit.toLowerCase()]
    } as HandicapData));
    return result
  }
  reloadPage(){
    location.reload();
  }
  getUserSetting(){
    this._userSettingService.getSetting().subscribe({
      next: (res) => {
         this.user = res;
      }
    });
  }
  playVideo(content: any) {
    this.videoPlaying = true;
    this.explainerModalRef = this._modalService.open(content, {
      centered: true,
      size: 'sm'
    });
  }
  ngOnDestroy(): void {
    this._chatService.disconnectWebSocket();
    // Signal to unsubscribe from all observables
    this.destroy$.next();
    this.destroy$.complete();
  }
}
