import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { TokenService } from '../../auth/token.service';
import { ChatService } from '../../services/chat/chat.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { mustMatch, regex } from '../../shared/services/validations';
import { ToasterService } from '../../shared/services/toaster.service';
import { CaddyService } from '../../services/caddy.service';
import { finalize, forkJoin, of, switchMap } from 'rxjs';
import { MediaService } from '../../services/media.service';
import { Caddy } from '../../model/caddy.model';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { LanguageService } from '../../services/language.service';
import { Language } from '../../model/language.model';
import { UserSettingService } from '../../services/user-setting.service';
import { SharedService } from '../../shared/services/shared.service';
import { TranslationService } from '../../shared/translation.service';
import { MediaModel } from '../../model/media.model';

@Component({
  selector: 'app-auth-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    DragScrollComponent,
    DragScrollItemDirective,
    CarouselModule
  ],
  templateUrl: './auth-modal.component.html',
  styleUrl: './auth-modal.component.scss'
})
export class AuthModalComponent {
  @Output() closeModal = new EventEmitter<boolean>();
  @Input() page = 'login';
  @ViewChild('nav', { read: DragScrollComponent }) ds!: DragScrollComponent;
  @Input() loadCaddy = false;
  @Input() inEmbeddedBrowser = false;
  loginForm: FormGroup;
  signupForm: FormGroup;
  resetForm: FormGroup;
  submitting = false;
  showPassword = false;
  formSubmitted = false;
  error = '';
  confirmPasswordError = false;
  accessToken:any;
  showConfirmPassword = false;
  signupSubmitted = false;
  verificationCode = '';
  verificationSubmitted = false;
  caddyList: Caddy[] = [];
  caddyPages: any [] = [];
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    autoHeight: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: false
  }
  expandLanguages = false;
  languageList: Language[] = [];
  selectedLanguage!: Language;
  selectedCaddy: Caddy | null = null;
  forgotForm: FormGroup;
  private playingAudio: HTMLAudioElement | null = null;
  caddyLoading = true;
  caddyLoadingArray = new Array(4);
  showResetPassword = false;
  constructor(
    private fb: FormBuilder,
    private _authService: AuthService,
    private _tokenService: TokenService,
    private _chatService: ChatService,
    private _toaster: ToasterService,
    private _router: Router,
    private _caddyService: CaddyService,
    private _mediaService: MediaService,
    private _languageService: LanguageService,
    private _userSettingsService: UserSettingService,
    private _sharedService: SharedService,
    private _translationService: TranslationService,
    private _cdr: ChangeDetectorRef
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.signupForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern(regex.email)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
    this.forgotForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.resetForm = this.fb.group({
      email: [''],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      verificationCode: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },{
      validators: [mustMatch('newPassword', 'confirmPassword')]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadCaddy'] && changes['loadCaddy'].currentValue === true) {
      this.getLanguageAndCaddies();
      this._chatService.getGps();
    }
  }

  close() {
    if(!this.submitting){
      if(this.page !== 'login' && this.page !== 'register'){
        this._router.navigateByUrl('/home');
      }
      this.closeModal.emit();
    }
  }
//region SOCIAL
  socialMediaLogin(type: string) {
    this._sharedService.setLocalStorage('redirectUrl', '/');
    this._authService.socialMediaLogin(type, this.page,'home');
  }
  //region LOGIN
  login() {
    Object.keys(this.loginForm.value).forEach((s: any) => {
      this.loginForm.controls[s].setValue(this.loginForm.value[s].trim());
    });
    this.formSubmitted = true;
    if (this.loginForm.valid) {
      const formValue = this.loginForm.value;
      delete formValue.confirmPassword;
      this.submitting = true;
      this._authService.login(formValue).subscribe({
        next: (res) => {
          this._tokenService.saveTokens(res.tokens);
          this._chatService.getGps();
          this._sharedService.setUser(res.user);
          this.getUserSetting();
          this._sharedService.setLocalStorage('email', res.user.email);
          this._router.navigateByUrl('/home');
          this.submitting = false;
          this.formSubmitted = false;
          this.close();
        },
        error: (error: HttpErrorResponse) => {
          this.submitting = false;
          console.log(error);
          // Handle the error here (optional)
        }
      });
    }
  }
  //region SIGNUP
  signup() {
    Object.keys(this.signupForm.value).forEach((s: any) => {
      this.signupForm.controls[s].setValue(this.signupForm.value[s].trim());
    });
    this.signupSubmitted = true;
    this.confirmPasswordError = false;
    if (this.signupForm.valid && !this.error) {
      const formValue = this.signupForm.value;
      // if(formValue.confirmPassword !== formValue.password){
      //   this.confirmPasswordError = true;
      //   return;
      // }
      
      delete formValue.confirmPassword;
      this.submitting = true;
      this._authService.signUp(formValue).subscribe({
        next: (res) => {
          // Handle the response here
          this._chatService.getGps();
          this.accessToken = res.tokens;
          this._tokenService.saveTokens(res.tokens);
          this._sharedService.setUser(res.user);
          this._sharedService.setLocalStorage('email', res.user.email);
          this.page = 'email-verification';
          this.submitting = false;
        },
        error: (error: HttpErrorResponse) => {
          this.submitting = false;
        }
      });
    }
  }

  passwordCheck(): void {
    const password = this.signupForm.value.password.trim();
    if(password){
      if (/\s+/.test(password)) {
        this.error = 'Password must not contain white spaces';
      } else if (!/([A-Z])+/.test(password)) {
        this.error = 'Password must contain at least 1 capital case letter';
      } else if (!/([a-z])+/.test(password)) {
        this.error = 'Password must contain at least 1 lower case letter';
      } else if (!/\d+/.test(password)) {
        this.error = 'Password must contain at least 1 number';
      } else if (!/\W+/.test(password)) {
        this.error = 'Password must contain at least 1 special character';
      } else {
        this.error = '';
      }
    } else {
      this.error = '';
    }
  }
  //region CONFIRM EMAIL
  confirmEmail(){
    this.verificationSubmitted = true;
    this.submitting = true;
    this._authService.verifyEmail(this.verificationCode, this.signupForm.value.email).subscribe({
      next:(res) => {
        this.submitting = false;
        this.getLanguageAndCaddies();
        this.signupForm.reset();
        this.signupForm.clearValidators();
        this._chatService.getGps();
        this._toaster.success("Your email has been successfully verified.","Success");
        this.page = 'language-caddy';
      },
      error:(error) => {
        this.submitting = false;
      }
    })
  }
  //region CADDY AND LANG
  getLanguageAndCaddies() {
    this.caddyLoading = true;   
    forkJoin({
      languages: this._languageService.getAll(),
      caddies: this._caddyService.getAll()
    }).pipe(
      switchMap(({ languages, caddies }) => {
        // Process languages
        this.languageList = [...languages.results];
        const languageImageIds= languages.results.map(x => x.image).filter(Boolean);

        
        // Process caddies
        this.caddyList = caddies.results;
        const imageIds = this.caddyList.map(caddy => caddy.image).filter(Boolean); // Ensure only valid image IDs are sent
        return forkJoin([
          languageImageIds.length > 0 ?this._mediaService.getMedia(languageImageIds): of([]),
          imageIds.length > 0  ? this._mediaService.getMedia(imageIds): of([])
        ])
      })
    ).subscribe({
      next: ([languageMediaResponse, caddyMediaResponse]) => {
        // Process media for caddies
        this.selectedCaddy = this.caddyList[0];
        this.caddyList.forEach(caddy => {
          const media = caddyMediaResponse.find(x => x.id === caddy.image);
          caddy.imageUrl = media ? media.url : ''; // Set imageUrl or fallback to empty string
        });

        this.languageList.forEach(lang => {
          const media = languageMediaResponse.find(x => x.id === lang.image);
          lang.imageUrl = media ? media.url : ''; // Set imageUrl or fallback to empty string
        });
        this.selectedLanguage = this.languageList[0];
        this._cdr.detectChanges();
        // Split caddies into pages
        this.caddyPages = this.splitArrayIntoChunks(this.caddyList, 4);
  
        // Set loading to true once both data are fully processed
        this.caddyLoading = false;
      },
      error: (err) => {
        console.error('Error fetching data:', err); // Handle error case
      }
    });
  }
  

  splitArrayIntoChunks(array: any[], chunkSize: number): any[][] {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  selectCaddy(caddy: Caddy) {
    if (this.selectedCaddy && this.selectedCaddy.id === caddy.id && this.playingAudio) {
      this.playingAudio.pause();
      this.playingAudio.currentTime = 0;
    } else {
      this.selectedCaddy = caddy;
      this.playCaddyVoice(this.selectedCaddy.voiceSample);
    }
  }

  playCaddyVoice(id: string){
    if (this.playingAudio) {
      this.playingAudio.pause();
      this.playingAudio.currentTime = 0;
    }
    this._mediaService.getMedia([id]).subscribe({
      next: (res => {
        // Create a new audio element and play it
        this.playingAudio = new Audio(res[0].url);
        this.playingAudio.play().catch(error => {
          console.error('Error playing audio:', error);
        });
      })
    })
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }
//region SUBMIT CADDY AND LANGUAGE
  submitCaddyLanguage() {
    if (this.selectedCaddy && this.selectedLanguage) {
      this.submitting = true;
      if (this.playingAudio) {
        this.playingAudio.pause();
        this.playingAudio.currentTime = 0;
      }
      const updateLanguage$ = this._userSettingsService.updateLanguage(this.selectedLanguage.id);
      const updateCaddy$ = this._userSettingsService.updateCaddy(this.selectedCaddy.id!);
  
      forkJoin([updateLanguage$, updateCaddy$]).subscribe({
        next: ([languageResult, caddyResult]) => {
          this.submitting = false;
          this._toaster.success('successfully updated!',"Success");
          this._router.navigateByUrl('/home');
          this.close();
        },
        error: (err) => {
          this.submitting = false;
          // Handle errors here
          console.error('Error occurred:', err);
        }
      });
    }
  }
//region VISIT PAGE
  visitPage(url: string){
    this.close();
    // this._router.navigateByUrl(url);
    if (url === '/terms-of-service'){
      window.open('/assets/files/Golf.ai-Terms-of-Service.pdf', '_blank');
    } else {
      window.open('/assets/files/Golf.ai-Privacy-Policy.pdf', '_blank');
    }
  }
//region FORGOT PASSWORD
  forgotPassword() {
    Object.keys(this.forgotForm.value).forEach((s: any) => {
      this.forgotForm.controls[s].setValue(this.forgotForm.value[s].trim());
    });
    this.formSubmitted = true;
    if (this.forgotForm.valid) {
      this.submitting = true;
      this._authService.forgotPassword(this.forgotForm.value).subscribe({
        next: (res) => {
          this.submitting = false;
          this.formSubmitted = false;
          this._toaster.success('Please check you mail', 'Successfully submitted');
          this._sharedService.setLocalStorage('reset-email', this.forgotForm.value.email);
          this.page = 'reset-password';
        },
        error: (error: HttpErrorResponse) => {
          this.submitting = false;          
          // Handle the error here (optional)
        }
      });
    }
  }
  //region RESET PASSWORD
  submitResetForm() {
    Object.keys(this.resetForm.value).forEach((s: any) => {
      this.resetForm.controls[s].setValue(this.resetForm.value[s].trim());
    });
    this.confirmPasswordError = false;
    this.formSubmitted = true;
    const email = localStorage.getItem('reset-email');
    if (this.resetForm.valid && !this.error && email) {
      this.resetForm.patchValue({email});
      const formValue = this.resetForm.value;
      if(formValue.confirmPassword !== formValue.newPassword){
        this.confirmPasswordError = true;
        return;
      }
      
      delete formValue.confirmPassword;
      this.submitting = true;
      this._authService.resetPassword(formValue).pipe(
        finalize(() => {
          this.submitting = false;
        })
      ).subscribe({
        next: (res) => {
          this.formSubmitted = false;
          this._sharedService.removeFromLocalStorage('reset-email');
          this._toaster.success('Successfully updated your password!',"Success");
          this.page = 'login';          
        },
        error: (error: HttpErrorResponse) => {
          this._toaster.error(error.message,"Error");
        }
      });
    }
  }
  getUserSetting() {
    this._userSettingsService.getSetting().subscribe({
      next: (res) => {
        if(res.caddy && res.caddy.image){
          this._mediaService.getMedia([res.caddy.image])
          .subscribe(mediaRes => {
            res.caddy!.imageUrl= mediaRes[0].url;
          })
        }
        this._sharedService.setUser(res);
        this._sharedService.setLocalStorage('email', res.email);
        this._translationService.setLanguage(res.language != null ? res.language.code : "en");
        this._chatService.onListenWebsocket();
      }
    });
  }
}
