import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChatService } from '../../../services/chat/chat.service';
import {
  AskQuestionModel,
  chat,
  messages,
} from '../../../model/chat.model';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from '../../../pipes/safe-html/safe-html.pipe';
import { ToasterService } from '../../../shared/services/toaster.service';
import { v4 as uuidv4 } from 'uuid';
import { finalize, Subject, Subscription, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { SharedService } from '../../../shared/services/shared.service';
import { User } from '../../../model/user.model';

@Component({
  selector: 'app-voice-history',
  standalone: true,
  imports: [CommonModule, SafeHtmlPipe, FormsModule, TranslatePipe],
  providers:[TranslatePipe],
  templateUrl: './voice-history.component.html',
  styleUrl: './voice-history.component.scss',
})
export class VoiceHistoryComponent implements OnInit, OnDestroy {
  currentIndex: number = 0;
  isSpeaking: boolean = false;
  pageNumber = 1;
  hasMoreItems: boolean = false;
  pageSize = 10;
  noResult = false;
  answerListening = false;
  private destroy$ = new Subject<void>();
  chatId = '';
  histories: chat[] = [];
  regenerateQuestionIndex = 0;
  textQuestion = '';
  isLoading = false;
  loadingMoreHistory = false;
  user:User | null= null;
  loadingArray = new Array(2);
  constructor(
    private _chatService: ChatService,
    private _toaster: ToasterService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _translatePipe: TranslatePipe,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this._sharedService.user$.subscribe(user => {
      this.user = user;
    });
    this._chatService
      .onListenWebsocket()
      .then(() => {})
      .catch((error) => {
        this._toaster.error(this._translatePipe.transform("SocketConnectionError"),this._translatePipe.transform("Error"));
        console.error('Failed to connect to WebSocket:', error);
      });
    this._activatedRoute.queryParams
    .subscribe((params) => {
      if (params['id']) {
        this.chatId = params['id'];
        this.getMessagesFromId();
      }
    });
    // this.getHistory();
   
      this._chatService.audioEnded()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res === true) {
          this.answerListening = false;
          this.histories.map((item) => (item.listening = false));
        }
      })
      this._chatService.getMessages()
      .pipe(takeUntil(this.destroy$))
      .subscribe((message) => {
        if (message.event === 'ANSWER_CHAT_ID') {
          this.chatId = message.data;
        } else if (message.event === 'ANSWER_TEXT_CHUNK') {
          // this.isRegenerating= false;
          this.histories[this.histories.length - 1].answer += message.data;
          this.histories[this.histories.length - 1].loading = false;
        } else if (message.event === 'ANSWER_ERROR') {
          this._toaster.error(message.error,this._translatePipe.transform("Error"));
          this._router.navigate(['/']);
        }
      })
      this._chatService.getError()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this._toaster.error(res,this._translatePipe.transform("Error"));
      })
  }
  truncateWords(text: string) {
    const words = text.split(' ');
    if (words.length <= 80) return text;
    return words.slice(0, 80).join(' ') + '...';
  }
  copyText(content: string) {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        this._toaster.success(this._translatePipe.transform("CopiedText"),this._translatePipe.transform("Success"));
      })
      .catch((err) => {
        this._toaster.error(this._translatePipe.transform("FailedToCopy"),this._translatePipe.transform("Error"));
      });
  }
  hearAnswer(text: string, index: number) {
    this.histories[index].listening = true;
    this.answerListening = true;
    const id = uuidv4();
    this.histories[index].refId = id;
    this._chatService.textToAudio(text, id);
  }
  stopListening(index: number) {
    if (this.histories[index].refId) {
      this._chatService.stopThisAudio(this.histories[index].refId);
      this.histories[index].listening = false;
    }
  }
  loadMoreHistory() {
    this.pageNumber++;
    this.loadingMoreHistory = true;
    this.getMessagesFromId();
  }
  getMessagesFromId() {
    this.isLoading = true;
    this.hasMoreItems = false;
    this._chatService
      .getMessageFromId(this.chatId, this.pageSize, this.pageNumber)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })       
      )
      .subscribe({
        next: (res: messages) => {
          this.processMessages(res);
        },
      });
  }
  regenerateAnswer() {
    this.histories[this.histories.length - 1].answer = '';
    this.histories[this.histories.length - 1].loading = true;
    let model: AskQuestionModel = {
      chatId: this.chatId,
      clientDateTime: new Date(),
      textQuestion: this.histories[this.histories.length -1].question,
      audioQuestion:'',
      mediaKeys:[],
      regenerate: true
    };
    this._chatService.askQuestionV2(model);
  }
  navigateToVoiceRecording() {
    this._router.navigate(['/voice-response'], {
      queryParams: { id: this.chatId },
    });
  }
  askQuestion() {
    if (!this.textQuestion) {
      return this._toaster.error(this._translatePipe.transform("QuestionRequired"),this._translatePipe.transform("Error"));
    }
    let model: AskQuestionModel = {
      chatId: this.chatId,
      clientDateTime: new Date(),
      textQuestion: this.textQuestion,
      audioQuestion: '',
      mediaKeys: [],
      regenerate: false,
    };
    this.histories.push({
      question: this.textQuestion,
      answer: '',
      loading: true,
      id: '',
    });
    this.textQuestion = '';
    this._chatService.askQuestionV2(model);
  }

  processMessages(res: messages, isShared = false) {
    // if(this.loadingMoreHistory){
    //   res.results.filter(rs => rs.role === 'assistant').forEach((item, index) => {
    //     if (item.role === 'assistant'){
    //       const questionIndex = res.results.findIndex(lm => lm.id === item.questionRef );
    //       if(questionIndex !== -1){
    //         this.histories.push({ 
    //           answer:  item.content, 
    //           question: res.results[questionIndex].content, 
    //           loading: false ,
    //           id: res.results[questionIndex].id
    //         });
    //       }
    //     }
    //   });
    // } else {
      res.results =  res.results.reverse();
      res.results.filter(rs => rs.role === 'assistant').forEach((item, index) => {
        if (item.role === 'assistant'){
          const questionIndex = res.results.findIndex(lm => lm.id === item.questionRef );
          if(questionIndex !== -1){
            if(this.loadingMoreHistory){
              this.histories.unshift({ 
                answer:  item.content, 
                question: res.results[questionIndex].content,
                loading: false,
                id: res.results[questionIndex].id
              });
            }
            else {
              this.histories.push({ 
                answer:  item.content, 
                question: res.results[questionIndex].content,
                loading: false,
                id: res.results[questionIndex].id
              });
            }
          }
        }
      })
    // }
    if(res.totalResults > (this.histories.length * 2)){
      this.hasMoreItems = true;
    }
    this.loadingMoreHistory = false;
  }
  ngOnDestroy() {
    const playingIndex = this.histories.findIndex((item) => item.listening);
    if (playingIndex !== -1) {
      this.stopListening(playingIndex);
    }
    this._chatService.disconnectWebSocket();
      // Signal to unsubscribe from all observables
      this.destroy$.next();
      this.destroy$.complete();
  }
}
