import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../token.service';
import { map, take } from 'rxjs';

export const noAuthGuard = () => {
  const tokenService = inject(TokenService);
  const router = inject(Router);

  return tokenService.isSignedIn$.pipe(
    take(1),
    map(isSignedIn => {
      if (isSignedIn) {
        router.navigate(['/home']);
        return false;
      }
      return true;
    })
  );
};