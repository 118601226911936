export namespace Constants {
  export const GolfDistance = {
    menDistances: [
      {
        handicapRange: '20+',
        distances: [
          {
            club: 'Driver',
            yards: 212,
            meters: 193.9,
          },
          {
            club: '3-wood',
            yards: 157,
            meters: 143.6,
          },
          {
            club: '3-hybrid',
            yards: 152,
            meters: 139,
          },
          {
            club: '4i',
            yards: 146.9,
            meters: 134.3,
          },
          {
            club: '5i',
            yards: 140.5,
            meters: 128.5,
          },
          {
            club: '6i',
            yards: 137.5,
            meters: 125.7,
          },
          {
            club: '7i',
            yards: 128.9,
            meters: 117.9,
          },
          {
            club: '8i',
            yards: 123.8,
            meters: 113.2,
          },
          {
            club: '9i',
            yards: 113.1,
            meters: 103.4,
          },
          {
            club: 'PW',
            yards: 94.6,
            meters: 86.5,
          },
          {
            club: '52°',
            yards: 80,
            meters: 73.2,
          },
          {
            club: '56°',
            yards: 65,
            meters: 59.4,
          },
          {
            club: '60°',
            yards: 50,
            meters: 45.7,
          },
        ],
      },
      {
        handicapRange: '15-20',
        distances: [
          {
            club: 'Driver',
            yards: 222,
            meters: 203.0,
          },
          {
            club: '3-wood',
            yards: 166.5,
            meters: 152.2,
          },
          {
            club: '3-hybrid',
            yards: 160.5,
            meters: 146.8,
          },
          {
            club: '4i',
            yards: 156.6,
            meters: 143.2,
          },
          {
            club: '5i',
            yards: 148.1,
            meters: 135.4,
          },
          {
            club: '6i',
            yards: 143.9,
            meters: 131.6,
          },
          {
            club: '7i',
            yards: 135.0,
            meters: 123.4,
          },
          {
            club: '8i',
            yards: 129.0,
            meters: 118.0,
          },
          {
            club: '9i',
            yards: 118.6,
            meters: 108.4,
          },
          {
            club: 'PW',
            yards: 101.6,
            meters: 92.9,
          },
          {
            club: '52°',
            yards: 87.5,
            meters: 80.0,
          },
          {
            club: '56°',
            yards: 73.75,
            meters: 67.4,
          },
          {
            club: '60°',
            yards: 57.5,
            meters: 52.6,
          },
        ],
      },
      {
        handicapRange: '10-15',
        distances: [
          {
            club: 'Driver',
            yards: 234,
            meters: 214.0,
          },
          {
            club: '3-wood',
            yards: 184,
            meters: 168.3,
          },
          {
            club: '3-hybrid',
            yards: 177,
            meters: 161.8,
          },
          {
            club: '4i',
            yards: 167.1,
            meters: 152.8,
          },
          {
            club: '5i',
            yards: 156.5,
            meters: 143.1,
          },
          {
            club: '6i',
            yards: 150.5,
            meters: 137.6,
          },
          {
            club: '7i',
            yards: 141.4,
            meters: 129.3,
          },
          {
            club: '8i',
            yards: 134.3,
            meters: 122.8,
          },
          {
            club: '9i',
            yards: 124.0,
            meters: 113.4,
          },
          {
            club: 'PW',
            yards: 108.2,
            meters: 98.9,
          },
          {
            club: '52°',
            yards: 95,
            meters: 86.9,
          },
          {
            club: '56°',
            yards: 82.5,
            meters: 75.4,
          },
          {
            club: '60°',
            yards: 65,
            meters: 59.4,
          },
        ],
      },
      {
        handicapRange: '5-10',
        distances: [
          {
            club: 'Driver',
            yards: 246,
            meters: 224.9,
          },
          {
            club: '3-wood',
            yards: 198,
            meters: 181.1,
          },
          {
            club: '3-hybrid',
            yards: 189,
            meters: 172.8,
          },
          {
            club: '4i',
            yards: 175.9,
            meters: 160.8,
          },
          {
            club: '5i',
            yards: 163.9,
            meters: 149.9,
          },
          {
            club: '6i',
            yards: 157.0,
            meters: 143.6,
          },
          {
            club: '7i',
            yards: 147.3,
            meters: 134.7,
          },
          {
            club: '8i',
            yards: 139.2,
            meters: 127.3,
          },
          {
            club: '9i',
            yards: 128.4,
            meters: 117.4,
          },
          {
            club: 'PW',
            yards: 113.7,
            meters: 104.0,
          },
          {
            club: '52°',
            yards: 102.5,
            meters: 93.7,
          },
          {
            club: '56°',
            yards: 91.25,
            meters: 83.4,
          },
          {
            club: '60°',
            yards: 72.5,
            meters: 66.3,
          },
        ],
      },
      {
        handicapRange: 'Less than 5',
        distances: [
          {
            club: 'Driver',
            yards: 260,
            meters: 237.7,
          },
          {
            club: '3-wood',
            yards: 219.5,
            meters: 200.7,
          },
          {
            club: '3-hybrid',
            yards: 203.5,
            meters: 186.1,
          },
          {
            club: '4i',
            yards: 187.0,
            meters: 171.0,
          },
          {
            club: '5i',
            yards: 172.9,
            meters: 158.1,
          },
          {
            club: '6i',
            yards: 163.9,
            meters: 149.9,
          },
          {
            club: '7i',
            yards: 154.0,
            meters: 140.8,
          },
          {
            club: '8i',
            yards: 144.6,
            meters: 132.2,
          },
          {
            club: '9i',
            yards: 133.6,
            meters: 122.2,
          },
          {
            club: 'PW',
            yards: 119.2,
            meters: 109.0,
          },
          {
            club: '52°',
            yards: 110,
            meters: 100.6,
          },
          {
            club: '56°',
            yards: 100,
            meters: 91.4,
          },
          {
            club: '60°',
            yards: 80,
            meters: 73.2,
          },
        ],
      },
    ],
    womenDistances: [
      {
        handicapRange: '20+',
        distances: [
          {
            club: 'Driver',
            yards: 169.6,
            meters: 155.1,
          },
          {
            club: '3-wood',
            yards: 125.6,
            meters: 114.9,
          },
          {
            club: '3-hybrid',
            yards: 121.6,
            meters: 111.2,
          },
          {
            club: '4i',
            yards: 117.5,
            meters: 107.4,
          },
          {
            club: '5i',
            yards: 112.4,
            meters: 102.8,
          },
          {
            club: '6i',
            yards: 110.0,
            meters: 100.6,
          },
          {
            club: '7i',
            yards: 103.1,
            meters: 94.3,
          },
          {
            club: '8i',
            yards: 99.0,
            meters: 90.6,
          },
          {
            club: '9i',
            yards: 90.5,
            meters: 82.7,
          },
          {
            club: 'PW',
            yards: 75.7,
            meters: 69.2,
          },
          {
            club: '52°',
            yards: 64.0,
            meters: 58.6,
          },
          {
            club: '56°',
            yards: 52.0,
            meters: 47.5,
          },
          {
            club: '60°',
            yards: 40.0,
            meters: 36.6,
          },
        ],
      },
      {
        handicapRange: '15-20',
        distances: [
          {
            club: 'Driver',
            yards: 182.0,
            meters: 166.5,
          },
          {
            club: '3-wood',
            yards: 136.5,
            meters: 124.8,
          },
          {
            club: '3-hybrid',
            yards: 131.6,
            meters: 120.4,
          },
          {
            club: '4i',
            yards: 128.4,
            meters: 117.4,
          },
          {
            club: '5i',
            yards: 121.4,
            meters: 111.0,
          },
          {
            club: '6i',
            yards: 118.0,
            meters: 107.9,
          },
          {
            club: '7i',
            yards: 110.7,
            meters: 101.2,
          },
          {
            club: '8i',
            yards: 105.8,
            meters: 96.8,
          },
          {
            club: '9i',
            yards: 97.3,
            meters: 88.9,
          },
          {
            club: 'PW',
            yards: 83.3,
            meters: 76.2,
          },
          {
            club: '52°',
            yards: 71.8,
            meters: 65.6,
          },
          {
            club: '56°',
            yards: 60.5,
            meters: 55.3,
          },
          {
            club: '60°',
            yards: 47.2,
            meters: 43.1,
          },
        ],
      },
      {
        handicapRange: '10-15',
        distances: [
          {
            club: 'Driver',
            yards: 196.6,
            meters: 179.8,
          },
          {
            club: '3-wood',
            yards: 154.6,
            meters: 141.4,
          },
          {
            club: '3-hybrid',
            yards: 148.7,
            meters: 135.9,
          },
          {
            club: '4i',
            yards: 140.4,
            meters: 128.4,
          },
          {
            club: '5i',
            yards: 131.5,
            meters: 120.2,
          },
          {
            club: '6i',
            yards: 126.4,
            meters: 115.6,
          },
          {
            club: '7i',
            yards: 118.8,
            meters: 108.6,
          },
          {
            club: '8i',
            yards: 112.8,
            meters: 103.2,
          },
          {
            club: '9i',
            yards: 104.2,
            meters: 95.3,
          },
          {
            club: 'PW',
            yards: 90.9,
            meters: 83.1,
          },
          {
            club: '52°',
            yards: 79.8,
            meters: 73.0,
          },
          {
            club: '56°',
            yards: 69.3,
            meters: 63.3,
          },
          {
            club: '60°',
            yards: 54.6,
            meters: 49.9,
          },
        ],
      },
      {
        handicapRange: '5-10',
        distances: [
          {
            club: 'Driver',
            yards: 211.6,
            meters: 193.4,
          },
          {
            club: '3-wood',
            yards: 170.3,
            meters: 155.7,
          },
          {
            club: '3-hybrid',
            yards: 162.5,
            meters: 148.6,
          },
          {
            club: '4i',
            yards: 151.3,
            meters: 138.3,
          },
          {
            club: '5i',
            yards: 141.0,
            meters: 128.9,
          },
          {
            club: '6i',
            yards: 135.0,
            meters: 123.5,
          },
          {
            club: '7i',
            yards: 126.7,
            meters: 115.8,
          },
          {
            club: '8i',
            yards: 119.7,
            meters: 109.5,
          },
          {
            club: '9i',
            yards: 110.4,
            meters: 101.0,
          },
          {
            club: 'PW',
            yards: 97.8,
            meters: 89.4,
          },
          {
            club: '52°',
            yards: 88.2,
            meters: 80.6,
          },
          {
            club: '56°',
            yards: 78.5,
            meters: 71.7,
          },
          {
            club: '60°',
            yards: 62.4,
            meters: 57.0,
          },
        ],
      },
      {
        handicapRange: 'Less than 5',
        distances: [
          {
            club: 'Driver',
            yards: 228.8,
            meters: 209.2,
          },
          {
            club: '3-wood',
            yards: 193.2,
            meters: 176.6,
          },
          {
            club: '3-hybrid',
            yards: 179.1,
            meters: 163.8,
          },
          {
            club: '4i',
            yards: 164.6,
            meters: 150.5,
          },
          {
            club: '5i',
            yards: 152.2,
            meters: 139.1,
          },
          {
            club: '6i',
            yards: 144.2,
            meters: 131.9,
          },
          {
            club: '7i',
            yards: 135.5,
            meters: 123.9,
          },
          {
            club: '8i',
            yards: 127.2,
            meters: 116.3,
          },
          {
            club: '9i',
            yards: 117.6,
            meters: 107.5,
          },
          {
            club: 'PW',
            yards: 104.9,
            meters: 95.9,
          },
          {
            club: '52°',
            yards: 96.8,
            meters: 88.5,
          },
          {
            club: '56°',
            yards: 88.0,
            meters: 80.4,
          },
          {
            club: '60°',
            yards: 70.4,
            meters: 64.4,
          },
        ],
      },
    ],
  };
}
