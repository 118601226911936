<div class="GolfDigestBg">
    <div class="GolfDigestBg__head">
        <h1>What’s In My Bag</h1>
        <div class="d-flex justify-content-center align-items-center mt-3">
            <img src="assets/images/golfDigest_logo.svg" alt="">
            <div class="mx-3">
                <img src="assets/images/closeIcon.svg" alt="">
            </div>
            <img src="assets/images/golftxtLogo.svg" alt="">
        </div>
    </div>
    <router-outlet></router-outlet>
</div>