<div class="voiceChat_history">
    <h3>{{'VoiceChatHistoryTitle' | translate}}</h3>
    <div class="load-more-btn-wrap">
        <button *ngIf="hasMoreItems && !isLoading" (click)="!loadingMoreHistory ? loadMoreHistory() : ''">
            <div class="dotBg" *ngIf="loadingMoreHistory">
                <div class="dot-flashing"></div>
            </div>
            <span *ngIf="!loadingMoreHistory">
                <img src="assets/images/common/refresh_green.svg" alt="">
                {{'LoadOlderChats' | translate}}
            </span>
        </button>
    </div>
    <!-- voiceChat_history__box -->

    <div class="voiceChat_history__box">
        @if (isLoading) {
            @for (item of loadingArray; track $index) {
                <div class="cmnt userCmnt loadingItem">
                    <div class="cmnt__box">
                        <div data-bs-toggle="modal" data-bs-target="#detailModal" class="cmnt__box__txt">
                            <span class="profile voice noImage">
                                <div class="profile-letter">SA</div>
                            </span>
                            <div class="d-block">
                                <h6>You</h6>
                                <p><i>xxxxxxx xxxxxx xxxxxxxx xxxxx</i></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cmnt adminCmnt loadingItem">
                    <div class="cmnt__box">
                        <div data-bs-toggle="modal" data-bs-target="#detailModal" class="cmnt__box__txt"><img
                                src="assets/images/logoIcon.svg" alt="">
                            <div class="d-block">
                                <h6>Golf.ai</h6>
                                <p><i>xxxxxxx xxxxxx xxxxxxxx xxxxxxxxxxxx xxxxxx xxxxxxxx xxxxx</i><br>
                                    <i>xxxxxxx xxxxxx xxxxxxxx xxxxx</i>
                                    <i>xxxxxxx xxxxxx xxxxxxxx xxxx xxxxx x xxxxxxxxxx xx</i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            } 
        } @else {
            <ng-container *ngFor="let history of histories; let i = index">
                <!-- userCmnt -->
                <div class="cmnt userCmnt">
                    <!-- cmnt__box -->
                    <div class="cmnt__box">
                        <div class="cmnt__box__txt" data-bs-toggle="modal" data-bs-target="#detailModal">
                            <span class="profile voice" [class.noImage]="!user?.image">
                                <img *ngIf="user?.image" [src]="user?.image" alt="">
                                <div *ngIf="!user?.image" class="profile-letter">{{user?.name?.substring(0,
                                    2)?.toUpperCase()}}</div>
                                <!-- <img src="assets/images/prfl1.png" alt=""> -->
                            </span>
                            <div class="d-block">
                                <h6>{{'You' | translate}}</h6>
                                <p>{{history.question}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- cmnt__box -->
                </div>
                <!-- adminCmnt -->
                <div class="cmnt adminCmnt">
                    <!-- cmnt__box -->
                    <div class="cmnt__box">
                        <div class="cmnt__box__txt" data-bs-toggle="modal" data-bs-target="#detailModal">
                            <img src="assets/images/logoIcon.svg" alt="">
                            <div class="d-block">
                                <h6>Golf.ai</h6>
                                <ng-container *ngIf="history.loading">{{'Generating' | translate}} ...</ng-container>
                                <p [innerHTML]="history.answer | safeHtml"></p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <ul>
                                <!-- <li>
                                <a href="javascript:void(0)">
                                  <img src="assets/images/delete.svg" alt="">
                                </a>
                            </li> -->
                                <!-- <li>
                                <a [class.disable]="!history.listening && answerListening" 
                                (click)="!history.listening && !answerListening ? hearAnswer(history.answer, i) : history.listening ? stopListening(i): ''">
                                  <img *ngIf="!history.listening" src="assets/images/common/speaker.svg" alt="">
                                  <img *ngIf="history.listening" src="assets/images/textareaIcon2-1.svg" alt="" class="stop">
                              </a>
                            </li> -->
                                <li>
                                    <a href="javascript:void(0)" (click)="copyText(history.answer)"><img
                                            src="assets/images/his_icon3.svg" alt=""></a>
                                </li>
                                <li *ngIf="histories.length-1 == i">
                                    <a class="btns" href="javascript:void(0)" toolTip="Regenerate"
                                        (click)="regenerateAnswer()"><img src="assets/images/his_icon2.svg" alt=""></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- cmnt__box -->
                </div>
                <!-- adminCmnt -->
            </ng-container>
        }
    </div>
    <!-- voiceChat_history__box -->
    <!-- inputBg -->
    <div class="inputBg mt-2">
        <div class="input-group">
            <input [placeholder]="'AskAFollowup' | translate" type="text" (keydown.enter)="askQuestion()"
                [(ngModel)]="textQuestion" class="form-control"
                aria-label="Dollar amount (with dot and two decimal places)">
            <span><img src="assets/images/microphone.svg" alt="" (click)="navigateToVoiceRecording()"></span>
            <span><img src="assets/images/send.svg" alt="" (click)="askQuestion()"></span>
        </div>
    </div>
    <!-- inputBg -->
    <!-- <div class="d-flex justify-center" *ngIf="hasMoreItems">
        <button (click)="!isLoading ? loadMoreHistory() : ''" class="loadmore">
            <div class="dotBg" *ngIf="isLoading">
                <div class="dot-flashing"></div><span>Load more</span>
            </div>
            <span *ngIf="!isLoading">Load more</span>
        </button>
    </div> -->
</div>