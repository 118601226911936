<!-- home__Cont -->
<div class="home__Cont chat__cont row justify-content-center">
    <div class="col-lg-6">
        <div>
            <h1 class="text-center title">
                {{'WelcomeMessage' | translate}}
            </h1>
            <p class="text-center fw-medium">{{"ChooseYourGolfAiPartner" | translate}}</p>
            <div class="d-flex py-2 partner-list" *ngIf="showSlider"
                [ngClass]="{'end-left': endOfLeft, 'end-right': endOfRight, 'align-items-end': loadingCaddy }">
                <ng-container *ngIf="loadingCaddy; else listView">
                    <div class="golf_partner_box loadingItem" *ngFor="let i of loadingArray">
                        <!-- golf_partner -->
                        <div class="golf_partner">
                            <div class="imgBlock"></div>
                        </div>
                        <!-- golf_partner -->
                        <!-- golf_partner_dtls -->
                        <div class="golf_partner_dtls">
                            <h5>
                                <span><i>xxxxxxxxxxxx</i></span>
                                <div class="flag">
                                    <!-- {{row.locationEmoji}} -->
                                    <!-- <img src="assets/images/flag.png" alt=""> -->
                                </div>
                            </h5>
                            <p><i>xxxxxxxxxxxxxxxx</i></p>
                        </div>
                        <!-- golf_partner_dtls -->
                    </div>
                </ng-container>
                <ng-template #listView>
                    <drag-scroll (reachesLeftBound)="endOfLeft = $event"
                        (reachesRightBound)="endOfRight = $event" #nav>
                        <!-- golf_partner_box -->
                        <div drag-scroll-item class="golf_partner_box" (click)="golferCaddy?.id != row.id ? updateCaddy(row,i) :''" *ngFor="let row of caddies; let i = index">
                            <!-- golf_partner -->
                            <div class="golf_partner">
                                <div class="imgBlock">
                                    <img [src]="row.imageUrl" alt="">
                                </div>
                            </div>
                            <!-- golf_partner -->
                            <!-- golf_partner_dtls -->
                            <div class="golf_partner_dtls">
                                <h5>
                                    <span>{{row.name}}</span>
                                    <div class="flag" [innerHTML]="row.locationEmoji">
                                        <!-- {{row.locationEmoji}} -->
                                        <!-- <img src="assets/images/flag.png" alt=""> -->
                                    </div>
                                </h5>
                                <p>{{row.personality}}</p>
                            </div>
                            <!-- golf_partner_dtls -->
                        </div>
                    </drag-scroll>
                    <!-- golf_partner_box -->
                    <div class="navArw left d-mobile-none" *ngIf="!endOfRight" (click)="moveRight()">
                        <img src="assets/images/next.svg" alt="">
                    </div>
                    <div class="navArw right d-mobile-none" *ngIf="!endOfLeft" (click)="moveLeft()">
                        <img src="assets/images/next.svg" alt="">
                    </div>
                </ng-template>
            </div>
            
            <h6 class="text-center mb-3">{{"AskQuestionHeader" | translate}}</h6>
            <!-- chat_and_fileUploadBg -->
            <div class="chat_and_fileUploadBg" *ngIf="showUploadModal" [ngClass]="{'active' : showUploadModal}">
                <!-- chat_and_fileUpload -->
                <div class="fileUploadBox">
                    <div class="btns">
                        <img src="assets/images/imgIconGrn.svg" multiple alt="" class="me-2">
                        {{'UploadImage' | translate}}
                        <input type="file" (change)="onFileChange($event,'media')" accept="image/*,video/*">
                    </div>
                    <div class="btns black" (click)="openCameraModal(mediaModal)">
                        {{'OpenCamera' | translate}}
                    </div>
                    <div class="btns green">
                        {{'UploadDocument' | translate}}
                        <input type="file" (change)="onFileChange($event, 'document')"
                            accept=".csv,.xls,.xlsx,.pdf,.doc,.docx,.txt" multiple>
                    </div>
                </div>
                <div class="file_preview" *ngIf="files.controls.length">
                    <div class="file_preview__items" *ngFor="let file of files.controls; let i = index">
                        <ng-container *ngIf="isImage(file.get('type')?.value); else notImage">
                            <img [src]="file.get('preview')?.value" [title]="fileName" alt="File Preview"
                                class="file-img" />
                        </ng-container>
                        <ng-template #notImage>
                            <ng-container *ngIf="isVideo(file.get('type')?.value); else notVideo">
                                <video [src]="file.get('preview')?.value" [title]="fileName" controls
                                    class="file-video"></video>
                            </ng-container>
                            <ng-template #notVideo>
                                <ng-container *ngIf="isAudio(file.get('type')?.value); else notAudio">
                                    <audio [src]="file.get('preview')?.value" [title]="fileName" controls
                                        class="file-audio"></audio>
                                </ng-container>
                                <ng-template #notAudio>
                                    <div class="file-icon" [title]="fileName" style="font-size: 70px;">📄</div>
                                </ng-template>
                            </ng-template>
                        </ng-template>
                        <button type="button" class="btn remove" (click)="removeFile(i)">
                            <img src="assets/images/closeIcon.svg" alt="">
                        </button>
                    </div>
                </div>
            </div>
            <!-- chat_and_fileUpload -->
            
            <div class="chat_and_fileUpload">
                <div class="d-flex">
                    <!-- <div class="addBtn" (click)="showUploadModal = !showUploadModal">
                        <img src="assets/images/plusFrame.svg" alt="">
                    </div> -->
                    <div class="position-relative">
                        <input type="text" [(ngModel)]="question" (keydown.enter)="sendMessage()" [placeholder]="'AskAnything' | translate">
                        <a *ngIf="question || files.controls.length > 0"  href="javascript:void(0)"
                            (click)="sending ? '' : sendMessage()">
                            <img src="assets/images/textareaIcon4.svg" alt="">
                        </a>
                        <a *ngIf="!question && files.controls.length == 0" href="javascript:void(0)"
                            routerLink="/voice-response">
                            <img src="assets/images/microPhoneIcon.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
            <!-- chat_and_fileUpload -->

            <!-- chatArea -->
            <div class="chatArea my-3">
                <h5>{{'QuestionsHeader' | translate}}</h5>
                <div class="chatBox">
                    <!-- chat -->
                    <div class="chat" (click)="setQuestion('Give me the recent news about Tiger.')">
                        <span>
                            <img src="assets/images/chatIcon4.svg" alt="" class="me-1">
                            {{"Question1Header" | translate}}
                        </span>
                        {{"Question1" | translate}}
                    </div>
                    <!-- chat -->
                    <!-- chat -->
                    <div class="chat" (click)="setQuestion('What happens if an animal moves my ball?')">
                        <span>
                            <img src="assets/images/chatIcon3.svg" alt="" class="me-1">
                            {{'Question2Header' | translate}}
                        </span>
                       {{"Question2" | translate}}
                    </div>
                    <!-- chat -->
                    <!-- chat -->
                    <div class="chat" (click)="setQuestion('Who is better off the tee, Rory or Scottie?')">
                        <span>
                            <img src="assets/images/chatIcon1.svg" alt="" class="me-1">
                            {{'Question3Header' | translate}}
                        </span>
                        {{"Question3" | translate}}
                    </div>
                    <!-- chat -->
                    <div class="chat" (click)="setQuestion('Tell me the current tour results.')">
                        <span>
                            <img src="assets/images/chat-circle-dots.svg" width="15px" alt="" class="me-1">
                            {{"Question4Header" | translate}}
                        </span>
                        {{"Question4" | translate}}
                    </div>
                    <!-- chat -->
                </div>
            </div>
            <!-- chatArea -->
             
        </div>
    </div>
</div>
<div class="hide-elements" *ngIf="isBrowser">
    <img src="assets/images/mic.svg">
    <img src="assets/images/playIcon.svg" alt="">
    <ng-lottie [options]="talkingOptions"></ng-lottie>
 </div>
<ng-template #mediaModal>
    <app-media-modal (capturedContent)="onMediaCaptured($event)"
        (closeModal)="closeMediaModal($event)"></app-media-modal>
</ng-template>