import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, take } from 'rxjs';

@Component({
  selector: 'splash-screen',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './splash-screen.component.html',
  styleUrl: './splash-screen.component.scss'
})
export class SplashScreenComponent {
  constructor(
    private _router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ){
    this._router.events
    .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
    )
    .subscribe(() => {
      this.removeLoader()
    });
  }

  removeLoader(){
    if (isPlatformBrowser(this.platformId)) {
      const loaderElement = document.querySelector('splash-screen');
      if (loaderElement) {
        loaderElement.remove();
      }
    }
    
  }

}
