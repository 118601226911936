import { inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TokenService } from '../token.service';
import { map, take } from 'rxjs';
import { SharedService } from '../../shared/services/shared.service';

export const golfDigestAuthGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const tokenService = inject(TokenService);
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);
  const sharedService = inject(SharedService);
  return tokenService.isSignedIn$.pipe(
    take(1),
    map(isSignedIn => {
      if (!isSignedIn) {
        const currentUrl = state.url;
        sharedService.setLocalStorage('redirectUrl', currentUrl);
        router.navigate(['/signup']);
        return false;
      }
      return true;
    })
  );
};