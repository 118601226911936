import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, ViewChild, viewChild } from '@angular/core';
import { RouterOutlet, RouterModule, ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthModalComponent } from '../auth-modal/auth-modal.component';
import { SharedService } from '../../shared/services/shared.service';
import { ToasterService } from '../../shared/services/toaster.service';
import { TokenService } from '../../auth/token.service';
import { UserSettingService } from '../../services/user-setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-public-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterModule,
    CommonModule,
    AuthModalComponent
  ],
  templateUrl: './public-layout.component.html',
  styleUrl: './public-layout.component.scss'
})
export class PublicLayoutComponent implements OnInit, AfterViewInit {
  authModalRef: NgbModalRef | undefined;
  authPage = 'login';
  openModal = false;
  thisYear = new Date().getFullYear();
  @ViewChild('authModal') authModal: any;
  loadCaddyData = false;
  inEmbeddedBrowser = false;
  constructor(
    private _modalService: NgbModal,
    config: NgbModalConfig,
    private _sharedService: SharedService,
    @Inject(PLATFORM_ID) private platformId: object,
    private _activatedRoute: ActivatedRoute,
    private _toaster: ToasterService,
    private _tokenService: TokenService,
    private _router: Router,
    private _settingService: UserSettingService
  ){
    config.backdrop = 'static';
		config.keyboard = false;
    config.centered = true;
    this._sharedService.openLoginModal$.subscribe(modal => {
      if(modal.status && this.authModal){
        this.openAuthModal(this.authModal, modal.window);
      }
    })
  }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
      this._activatedRoute.queryParams.subscribe(params => {
        if (params['error']) {
          this.openModal = true;
          this._toaster.error(params['error'],"Error");
          this.clearQueryParams();
        }
        if (params['page'] === 'register') {
            if(params['error']){
              this.authPage = params['page'];
              this.openModal = true;
            } else if (params['access_token'] && params['id_token'] && params['refresh_token']) {
              this._tokenService.saveTokens({
                AccessToken: params['access_token'],
                IdToken: params['id_token'],
                RefreshToken: params['refresh_token']
              });
              this.openModal = true;
              this.loadCaddyData = true;
              this.authPage = 'language-caddy';
              this.clearQueryParams();
              this.getUserSetting();
            }
        }
        if (params['type'] === 'register') {
          this.openAuthModal(this.authModal, 'register');
        }
      });
      if (this.isEmbeddedBrowser()) {
        this.inEmbeddedBrowser = true;
      }
    }
  }

  getUserSetting() {
    if (isPlatformBrowser(this.platformId)) {
      this._settingService.getSetting().subscribe({
        next: (res) => {
          this._sharedService.setUser(res);
          this._sharedService.setLocalStorage('email', res.email)      
          let socketEnabled = false;
          if(!socketEnabled){
            // this._chatService.onListenWebsocket();
            socketEnabled = true;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.log(err)
        }
      });
    
    }    
  }

  clearQueryParams() {
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {},
      queryParamsHandling: ''
    });
  }

  ngAfterViewInit(): void {
    // Wait until the authModal is initialized and then open the modal if 'page' param exists
    if (this.authModal && this.authPage && this.openModal) {
      this.openAuthModal(this.authModal, this.authPage);
    }
  }

  openAuthModal(content: any, modalPage: string){
    if(isPlatformBrowser(this.platformId)){
      this.authPage = modalPage;
      this.authModalRef = this._modalService.open(content, { windowClass: 'auth-modal' });
    }
  }

  isEmbeddedBrowser(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    // Detect LinkedIn, Facebook, Instagram embedded browsers
    return /FBAN|FBAV|Instagram|LinkedIn/i.test(userAgent);
  }

  closeAuthModal(event: any) {
    this.authModalRef?.dismiss();
  }

}
