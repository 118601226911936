import { Component, Inject, OnInit, PLATFORM_ID, ViewChild, ViewChildren, viewChildren } from '@angular/core';
import { Router, RouterOutlet, RoutesRecognized } from '@angular/router';
import { SplashScreenComponent } from './layout/splash-screen/splash-screen.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ThemeService } from './shared/theme.service';
import { TranslatePipe } from './pipes/translate.pipe';
import { TranslationService } from './shared/translation.service';
import { AuthService } from './auth/auth.service';
import { UserSettingService } from './services/user-setting.service';
import { MediaService } from './services/media.service';
import { SharedService } from './shared/services/shared.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ChatService } from './services/chat/chat.service';
import { filter, pairwise } from 'rxjs';
import { AuthModalComponent } from './layout/auth-modal/auth-modal.component';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

declare var google: any;
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule, 
    RouterOutlet, 
    SplashScreenComponent,
    TranslatePipe,
    AuthModalComponent
  ],
  providers:[AuthService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'Golf.ai';
  // translations: { [key: string]: string } = {};
  constructor(
    private themeService: ThemeService, 
    private translationService: TranslationService, 
    private _authService: AuthService,
    private _settingService: UserSettingService,
    private _sharedService: SharedService,
    private _chatService: ChatService,
    private _router:Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private _mediaService: MediaService
  ){
    this.translationService.currentLang$.subscribe(() => {
      // this.translations = this.translationService.getTranslations();
    });
  }
  ngOnInit(): void {
    // subscribe router event
    this._router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    .subscribe((events: RoutesRecognized[]) => {
      this._sharedService.setPreviousUrl(events[0].urlAfterRedirects);
    });
    // this.translationService.loadTranslations('en');
    if (isPlatformBrowser(this.platformId)) {
      this.themeService.isDarkTheme.subscribe((isDarkTheme) => {
        if (isDarkTheme) {
          document.body.classList.add('dark-theme');
        } else {
          document.body.classList.remove('dark-theme');
        }
      });
    }

    if(this._authService.isSignedIn()){
      this._chatService.getGps();
      this.getUserSetting();
    }
  }
  getUserSetting() {
    if (isPlatformBrowser(this.platformId)) {
      this._settingService.getSetting().subscribe({
        next: (res) => {
          if(res.caddy && res.caddy.image){
            this._mediaService.getMedia([res.caddy.image])
            .subscribe(mediaRes => {
              res.caddy!.imageUrl= mediaRes[0].url;
              this._sharedService.setUser(res);
            })
          }
          else{
            this._sharedService.setUser(res);
          }
          this._sharedService.setLocalStorage('email', res.email)      
          let socketEnabled = false;
          if(!socketEnabled){
            // this._chatService.onListenWebsocket();
            socketEnabled = true;
          }
        },
        error: (err: HttpErrorResponse) => {
          console.log(err)
        }
      });
    }    
  }
  setLanguage(lang: string): void {
    this.translationService.setLanguage(lang);
  }

  getReverseGeocodingData(lat: any, lng: any): void {
    if (google){
      const latLong = new google.maps.LatLng(lat, lng);
      // This is making the Geocode request
      const geoCoder = new google.maps.Geocoder();
      geoCoder.geocode({ latLng: latLong },  (results: any, status: any) => {
          if (status !== google.maps.GeocoderStatus.OK) {
              alert(status);
          }
          // This is checking to see if the Geoeode Status is OK before proceeding
          // if (status === google.maps.GeocoderStatus.OK) {
          //   if (results.length > 3){
          //     this.sharedService.location = results[3].formatted_address;
          //   } else if (results.length > 2){
          //     this.sharedService.location = results[2].formatted_address;
          //   } else {
          //     this.sharedService.location = results[1].formatted_address;
          //   }
          // }
      });
    }
  }

}
