import { NgClass, CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgbModule, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';
import { Subscription, finalize, forkJoin, switchMap } from 'rxjs';
import { TokenService } from '../../auth/token.service';
import { AskQuestionModel } from '../../model/chat.model';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { ChatService } from '../../services/chat/chat.service';
import { MediaService } from '../../services/media.service';
import { MediaModalComponent } from '../../shared/components/media-modal/media-modal.component';
import { SharedService } from '../../shared/services/shared.service';
import { ToasterService } from '../../shared/services/toaster.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CaddyService } from '../../services/caddy.service';
import { Caddy } from '../../model/caddy.model';
import { UserSettingService } from '../../services/user-setting.service';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { User } from '../../model/user.model';
@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    DragScrollComponent,
    DragScrollItemDirective,
    NgClass,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslatePipe,
    MediaModalComponent,
    RouterModule,
    LottieComponent,
  ],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
  providers: [ToasterService, TranslatePipe],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          opacity: 1,
          visibility: 'visible',
          transform: 'scale(1)',
          right: '.75rem',
          bottom: '.75rem',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          visibility: 'hidden',
          transform: 'scale(0.5)',
          right: '-65px',
          bottom: '-48px',
        })
      ),
      transition('open => closed', [animate('0.2s')]),
      transition('closed => open', [animate('0.2s')]),
    ]),
  ],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild('nav', { read: DragScrollComponent }) ds!: DragScrollComponent;
  @ViewChild('G_listBg', { static: true }) container!: ElementRef;
  endOfLeft = false;
  endOfRight = false;
  openFilesSection = false;
  recording: boolean = false;
  audioUrl: string | undefined;
  timer: any;
  seconds: number = 0;
  minutes: number = 0;
  paused: boolean = false;
  isRecording: boolean = false;
  question: string = '';
  uploadForm: FormGroup;
  fileName: string = '';
  maxTotalSizeMB = 10;
  capturedPhoto: string | null = null;
  cameraModalRef: NgbModalRef | undefined;
  mediaModalRef: NgbModalRef | undefined;
  isSignedIn = false;
  private _subscription!: Subscription;
  permissionGranted = false;

  errorMessage: string | null = null;
  recordingSubscription: Subscription = new Subscription();
  errorSubscription: Subscription = new Subscription();
  mediaKeys: string[] = [];
  // videoModalRef: NgbModalRef | undefined;
  audioBlob: Blob | undefined;
  private audio: any;

  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: Blob[] = [];
  generatingText = false;
  disableButtons = false;

  allowedFileTypes = [
    'text/csv', // .csv
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/pdf', // .pdf
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'text/plain', // .txt
  ];
  // Allowed file types and MIME types for validation
  allowedDocumentExtensions = [
    'csv',
    'xls',
    'xlsx',
    'pdf',
    'doc',
    'docx',
    'txt',
  ];
  allowedImageMimeTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/webp',
  ];
  allowedVideoMimeTypes = [
    'video/mp4',
    'video/webm',
    'video/ogg',
    'video/x-msvideo',
    'video/mpeg',
  ];
  invalidFiles: File[] = [];
  talkingOptions: AnimationOptions = {
    path: '/assets/animations/speaking-home.json'
  }
  generatingOptions: AnimationOptions = {
    path: '/assets/animations/generating-home.json'
  }
  showSlider = false;
  sending = false;
  caddies: Caddy[] = [];
  caddyList: Caddy[] = [];
  showUploadModal = false;
  // currentCaddy: Caddy | undefined;
  golferCaddy: Caddy | null = null;
  loadingArray = new Array(4);
  loadingCaddy = false;
  user: User = new User();
  isBrowser = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private toaster: ToasterService,
    private _sharedService: SharedService,
    private _router: Router,
    private _tokenService: TokenService,
    private _mediaService: MediaService,
    private _chatService: ChatService,
    private _caddyService: CaddyService,
    @Inject(PLATFORM_ID) private platformId: object,
    private _translatePipe: TranslatePipe,
    private _settingService: UserSettingService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.audio = new Audio();
      this.showSlider = true;

    }

    this.uploadForm = this.fb.group({
      files: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this._sharedService.user$.subscribe({
      next: (res) => {
        if (res.caddy) {
          this.user = res;
          this.golferCaddy = res.caddy;
        }
      this.getAllCaddies();
      }
    });
    this.audioUrl = '';
    this.seconds = 0;
    this.minutes = 0;
    this._subscription = this._tokenService.isSignedIn$.subscribe(
      (isSignedIn) => {
        this.isSignedIn = isSignedIn;
      }
    );
  }
  getAllCaddies() {
    this.loadingCaddy = true;
    this._caddyService
          .getAll()
          .pipe(
            switchMap(caddyResponse => {
              this.caddyList = caddyResponse.results;
              let imageIds = caddyResponse.results.map(x => x.image);
              this.caddies = this.caddyList;
              this.loadingCaddy = false;
              return this._mediaService.getMedia(imageIds);
            })
          )
          .pipe(finalize(() => {
            this.loadingCaddy = false;
          }))
          .subscribe({
            next: (res) => {
              this.caddies.forEach(caddy => {
                caddy.imageUrl = res.find(x => x.id == caddy.image)?.url ?? '';
                // caddy.locationEmoji = this.countryCodeToEmoji(caddy.locationEmoji);
              });
              if (this.golferCaddy) {
                const index = this.caddies.findIndex(item => item.id === this.golferCaddy?.id);
                if (index !== -1) {
                  const [item] = this.caddies.splice(index, 1);
                  this.caddies.unshift(item);
                }
              }
            },
          });
  }
  countryCodeToEmoji(countryCode: string): string {
    // Ensure the country code is uppercase
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char => 127397 + char.charCodeAt(0));

    return String.fromCodePoint(...codePoints);
  }
  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  startRecording(): void {
    this.audioUrl = '';
    this.seconds = 0;
    this.minutes = 0;
    this.files.clear();
    this.disableButtons = true;

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          } else {
            console.error('Empty data chunk received, likely due to early stop.');
          }
        };
        this.mediaRecorder.onstop = () => {
          this.audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
          this.audioUrl = URL.createObjectURL(this.audioBlob);
          this.audioChunks = [];
          // this.submitAudioQuestion();
        };

        this.mediaRecorder.onstart = () => {
          console.log('Recording started');
        };

        this.mediaRecorder.onerror = (event) => {
          console.error('Recording error:', event);
        };

        this.mediaRecorder.onpause = () => {
          console.log('Recording paused');
        };

        this.mediaRecorder.onresume = () => {
          console.log('Recording resumed');
        };


        // Start recording if MediaRecorder is available
        if (this.mediaRecorder) {
          this.mediaRecorder.start();
          this.recording = true;
          this.isRecording = true;
          this.paused = false;
          this.startTimer();
        }
      })
      .catch((error) => {
        let errorMessage = 'AccessingMicrophoneError';
        if (
          error.name === 'NotAllowedError' ||
          error.name === 'PermissionDeniedError'
        ) {
          errorMessage =
            'MicrophoneAccessDeniedError';
        }
        this.toaster.error(this._translatePipe.transform(errorMessage),"Error");
      });
  }

  async stopRecording(): Promise<void> {
    if (this.isRecording) {
      this.generatingText = true;
      this.disableButtons = true;
      this.isRecording = false;
      if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
        this.mediaRecorder.stop();
        this.audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
        this.audioUrl = URL.createObjectURL(this.audioBlob);
        this.audioChunks = [];
        this.toaster.success(this._translatePipe.transform('SuccessfullyRecorded.'),this._translatePipe.transform("Success"));
      } else {
        // this.toaster.error(
        //   this._translatePipe.transform("StopRecordingError"), this._translatePipe.transform("Error")
        // );
      }
      this.clearTimer();
    }
  }
  startTimer(): void {
    this.timer = setInterval(() => {
      this.seconds++;
      if (this.seconds === 60) {
        this.seconds = 0;
        this.minutes++;
      }
    }, 1000);
  }
  clearTimer(): void {
    clearInterval(this.timer);
    // this.seconds = 0;
    // this.minutes = 0;
  }
  get formattedTime(): string {
    const mins = this.minutes < 10 ? `0${this.minutes}` : this.minutes;
    const secs = this.seconds < 10 ? `0${this.seconds}` : this.seconds;
    return `${mins}:${secs}`;
  }
  setQuestion(question: string) {
    this.question = question;
  }
  toggleRecording() {
    if (this.isRecording) {
      this.stopRecording();
    } else {
      this.startRecording();
    }
  }
  onFileChange(event: any, uploadType: string = '') {
    this.audioUrl = '';
    this.recording = false;
    if (event.target.files.length < 0) {
      return this.toaster.error(this._translatePipe.transform("FileRequired"), this._translatePipe.transform("Error"));
    }
    this.openFilesSection = false;
    let totalFiles = event.target.files.length + this.files.length;
    if (totalFiles > 3) {
      this.toaster.error(this._translatePipe.transform("FileLimitValidation"),this._translatePipe.transform("Error"));
      return;
    }
    const filesArray = this.files;
    this.invalidFiles = [];
    if (uploadType == 'document') {
      Array.from(event.target.files).forEach((file) => {
        if (!this.isValidDocumentType(file as File)) {
          this.invalidFiles.push(file as File);
        }
      });
    } else if (uploadType == 'media') {
      Array.from(event.target.files).forEach((file) => {
        if (!this.isValidFileTypeForMediaUPload(file as File)) {
          this.invalidFiles.push(file as File);
        }
      });
    } else {
      Array.from(event.target.files).forEach((file) => {
        if (!this.isValidFileType(file as File)) {
          this.invalidFiles.push(file as File);
        }
      });
    }
    if (this.invalidFiles.length > 0) {
      this.toaster.error(this._translatePipe.transform("FileTypeValidation"),this._translatePipe.transform("Error"));
      return;
    }
    // filesArray.clear(); // Clear previous selection
    // let files = [];
    for (let i = 0; i < event.target.files.length; i++) {
      let fileToUpload = event.target.files[i];
      this.fileName = fileToUpload ? fileToUpload.name : '';

      // Preview the file
      const reader = new FileReader();
      reader.onload = (e: any) => {
        filesArray.push(
          this.fb.group({
            name: fileToUpload.name,
            type: fileToUpload.type,
            size: fileToUpload.size,
            preview: e.target.result,
            file: fileToUpload,
          })
        );
        this.checkTotalSize();
      };
      if (fileToUpload) {
        reader.readAsDataURL(fileToUpload);
      }
    }
  }

  sendMessage() {
    if (!this.question.trim()) {
      this.toaster.error(
        this._translatePipe.transform("HomeQuestionValidation"),this._translatePipe.transform("Error")
      );
      return;
    }

    this.disableButtons = true;
    this.sending = true;
    this._chatService.onListenWebsocket()
      .then(() => {
        let questionModel = new AskQuestionModel();
        if (this.files.value.length > 0) {
          let fileNames = this.files.value.map((x: any) => x.name);
          this._mediaService
            .getMediaUrls(fileNames.join(','))
            .pipe(finalize(() => { }))
            .subscribe({
              next: (response1) => {
                let apiCalls: any[] = [];
                for (let i = 0; i < this.files.value.length; i++) {
                  apiCalls.push({
                    url: response1[i].put?.url,
                    blobContent: this.files.value[i].file,
                  });
                  this.mediaKeys.push(response1[i].get?.url!);
                }
                // Create an array of observables
                const apiObservables = apiCalls.map((x) =>
                  this._mediaService.uploadMediaToS3(x.url, x.blobContent)
                );
                forkJoin(apiObservables).subscribe({
                  next: (res) => {
                    questionModel.textQuestion = this.question;
                    questionModel.mediaKeys = response1;
                    this._sharedService.setQuestion(questionModel);
                    this.sending = false;
                    this._router.navigate(['answer']);
                  },
                })
              },
            });
        }
        //  else if (this.audioBlob) {
        //   if (this.audio) {
        //     this.audio.pause();
        //     this.audio.src = '';
        //     this.audio.load();
        //   }
        //   const fileName = this.generateUniqueFileName();
        //   this._mediaService
        //   .getMediaUrls(fileName)
        //   .pipe(
        //     switchMap((response1) => {
        //       questionModel.audioQuestion = response1[0].get?.url!;
        //       return this._mediaService.uploadMediaToS3(
        //         response1[0].put?.url!,
        //         this.audioBlob!
        //       );
        //     })
        //   )
        //   .pipe(finalize(() => {}))
        //   .subscribe({
        //     next: (res) => {
        //       this._sharedService.setQuestion(questionModel);
        //       this.sending = false;
        //       // this._router.navigate(['answer']);
        //       // this.getAudioResponse();
        //     },
        //   });
        // } 
        else {
          questionModel.textQuestion = this.question;
          this._sharedService.setQuestion(questionModel);
          this.sending = false;
          this._router.navigate(['answer']);
        }
      })
      .catch((error) => {
        this.disableButtons = false;
        console.error('Failed to connect to WebSocket:', error);
      });
  }

  get files(): FormArray {
    return this.uploadForm.get('files') as FormArray;
  }

  isImage(fileType: string): boolean {
    return fileType.startsWith('image/');
  }

  isVideo(fileType: string): boolean {
    return fileType.startsWith('video/');
  }

  isAudio(fileType: string): boolean {
    return fileType.startsWith('audio/');
  }
  removeFile(index: number): void {
    this.files.removeAt(index);
    this.checkTotalSize();
  }
  checkTotalSize(): void {
    const totalSizeMB = this.files.controls.reduce((total, file) => {
      return total + file.get('size')?.value / 1024 / 1024;
    }, 0);

    if (totalSizeMB > this.maxTotalSizeMB) {
      this.toaster.error(this._translatePipe.transform("FileSizeValidation"),this._translatePipe.transform("Error"));
    }
  }
  onMediaCaptured(photo: {
    name: string;
    type: string;
    size: number;
    preview: string;
    file: File;
  }): void {
    this.files.clear();
    const filesArray = this.files;
    filesArray.push(
      this.fb.group({
        name: photo.name,
        type: photo.type,
        size: photo.size,
        preview: photo.preview,
        file: photo.file,
      })
    );
    this.checkTotalSize();
    this.mediaModalRef?.dismiss();
  }
  openMediaModal(content: any) {
    this.openFilesSection = false;
    this.mediaModalRef = this.modalService.open(content, {
      backdrop: 'static',
      centered: true,
    });
  }
  closeMediaModal(event: any) {
    this.mediaModalRef?.dismiss();
  }

  // photo capture
  onCameraCaptured(photo: {
    name: string;
    type: string;
    size: number;
    preview: string;
    file: File;
  }): void {
    this.files.clear();
    const filesArray = this.files;
    filesArray.push(
      this.fb.group({
        name: photo.name,
        type: photo.type,
        size: photo.size,
        preview: photo.preview,
        file: photo,
      })
    );
    this.checkTotalSize();
    this.mediaModalRef?.dismiss();
  }
  openCameraModal(content: any) {
    this.openFilesSection = false;
    this.mediaModalRef = this.modalService.open(content, {
      backdrop: 'static',
      centered: true,
    });
  }
  closeCameraModal(event: any) {
    this.mediaModalRef?.dismiss();
  }
  navigateVoiceResponse() {
    if (!this.isSignedIn) {
      this.toaster.error(this._translatePipe.transform("LoginToContinue"),this._translatePipe.transform("Error"));
      this._router.navigate(['/']);
      this._sharedService.openAuthModal(true);
      return;
    }
    this._router.navigate(['voice-response']);
  }
  private isValidDocumentType(file: File): boolean {
    const mimeType = file.type;
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    // Check MIME type and file extension
    const isValidMimeType = this.allowedFileTypes.includes(mimeType);
    const isValidExtension = [
      'csv',
      'xls',
      'xlsx',
      'pdf',
      'doc',
      'docx',
      'txt',
    ].includes(fileExtension!);

    return isValidMimeType && isValidExtension;
  }
  private isValidFileTypeForMediaUPload(file: File): boolean {
    const mimeType = file.type;

    // Check if the file type is valid (image or video)
    return mimeType.startsWith('image/') || mimeType.startsWith('video/');
  }
  private isValidFileType(file: File): boolean {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const mimeType = file.type;

    // Check if the file is a valid document
    const isDocument = this.allowedDocumentExtensions.includes(fileExtension!);

    // Check if the file is a valid image
    const isImage =
      mimeType.startsWith('image/') &&
      this.allowedImageMimeTypes.includes(mimeType);

    // Check if the file is a valid video
    const isVideo =
      mimeType.startsWith('video/') &&
      this.allowedVideoMimeTypes.includes(mimeType);

    // The file is valid if it is a document, image, or video
    return isDocument || isImage || isVideo;
  }

  generateUniqueFileName(): string {
    const timestamp = Date.now();
    // Generate a random number
    const randomNum = Math.floor(Math.random() * 1e9);
    // Combine timestamp and random number to create a unique identifier
    const uniqueIdentifier = `${timestamp}-${randomNum}`;
    // Construct the unique filename with the file extension
    const uniqueFileName = `${uniqueIdentifier}.mp3`;
    return uniqueFileName;
  }
  getUserSetting() {
    this._settingService.getSetting().subscribe({
      next: (res) => {
        this.user = res;
        this.golferCaddy = res.caddy;
      }
    });
  }

  updateCaddy(caddy: Caddy, index: number) {
    const modalRef = this.modalService.open(ConfirmationComponent, {
      backdrop: 'static',
      centered: true
    });
    modalRef.componentInstance.message = "Are you sure to update your partner?";
    modalRef.componentInstance.title = "Update Partner";
    modalRef.result.then((result) => {
      if (result) {
        this._settingService.updateCaddy(caddy.id)
          .subscribe({
            next: (res) => {
              // Remove the caddy from the list
              const [item] = this.caddies.splice(index, 1);
              // add the caddy to the top of the list
              this.caddies.unshift(item);
              this.ds.moveTo(0);
              this.golferCaddy = caddy;
              this.user.caddy = caddy;
              this._sharedService.setUser(this.user);
              this.toaster.success("Partner updated successfully.", this._translatePipe.transform("Success"));
            }
          })
      }
    }, (reason) => {
      // User dismissed the modal

    });
  }
  ngOnDestroy(): void {
    this.clearTimer();
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this.recordingSubscription.unsubscribe();
    this.errorSubscription.unsubscribe();
    this.clearTimer(); // Cleanup timer when component is destroyed
  }
}
