import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { filter, Subscription } from 'rxjs';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SharedService } from '../shared/services/shared.service';
import { User } from '../model/user.model';
import { TranslatePipe } from '../pipes/translate.pipe';
import { AuthModalComponent } from './auth-modal/auth-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    AuthModalComponent,
    RouterOutlet, RouterModule, CommonModule, TranslatePipe],
  providers: [],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit, OnDestroy {
  expandMenu = true;
  private _subscription!: Subscription;
  user: User | null = null;
  prevUrl: string = '';
  currentUrl: string = '';
  isMobileMenuActive = false;
  isSignedIn = false;
  activeTab = '';
  constructor(
    private _sharedService: SharedService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    router.events.subscribe((val) => {
      if (isPlatformBrowser(this.platformId) && document) {
        const body = document.getElementsByTagName('body')[0];
        if (val instanceof NavigationEnd) {
          this.expandMenu = false;
          this.mobileSlideMenu = false;
          this.isMobileMenuActive = false;
          if(val.url !== '/settings'){
            this.activeTab = '';
          }
          
        }
      }
    });
  }

  ngOnInit() {
    this._sharedService.user$.subscribe(user => {
      this.user = user;
      if(this.user.email){
        this.isSignedIn = true;
      }
    });
    this._sharedService.isMyBagActive$.subscribe(res => {
      if(res){
        this.activeTab = res;
      }
    })
  }
  navigateToSettingPage(tabName: string){
    this._sharedService.setByBagSettingTab(tabName);
    this.router.navigateByUrl("/settings");
    this.activeTab = tabName;
    this.expandMenu = false;
    this.mobileSlideMenu = false;
    this.isMobileMenuActive = false;
  }
  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  mobileSlideMenu = false;
}
