<!-- home__Cont -->
<div class="home__Cont row">
    <div class="col-lg-6">
        <div class="box">
            <div class="box_header">
                <h4>Experience Golf with your partner</h4>
                <p>Choose your Ai player partner</p>
            </div>

            <div class="d-flex py-2 partner-list" [class.loading]="loadingCaddy">
                @if (loadingCaddy && !caddies.length){
                    <div class="golf_partner_box loadingItem" *ngFor="let i of loadingArray">
                        <!-- golf_partner -->
                        <div class="golf_partner">
                            <div class="imgBlock"></div>
                        </div>
                        <div class="golf_partner_dtls">
                            <h5>
                                <span><i>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</i></span>
                                <div class="flag">
                                    <!-- {{row.locationEmoji}} -->
                                    <!-- <img src="assets/images/flag.png" alt=""> -->
                                </div>
                            </h5>
                            <p><i>xxxxxxxxxxxx</i></p>
                        </div>
                        <!-- golf_partner_dtls -->
                    </div>
                } @else {
                    <drag-scroll (reachesLeftBound)="endOfLeft = $event" (reachesRightBound)="endOfRight = $event" #nav>
                        <!-- golf_partner_box -->
                        <div class="golf_partner_box" drag-scroll-item *ngFor="let row of caddies; let i = index">
                            <!-- golf_partner -->
                            <div class="golf_partner">
                                <div class="imgBlock">
                                    <img (click)="openAuthModal('register')" [src]="row.imageUrl" alt="">
                                    <div class="speakerBox" [class.playing]="row.playing" (click)="playCaddyVoice(row.voiceSample, i)">
                                        <!-- <img src="assets/images/speakerIcon.svg"  alt=""> -->
                                        <svg width="16" height="12" viewBox="0 0 16 12" class="speaker-icon" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3396 0.699545C12.6059 0.433428 13.0376 0.433493 13.3039 0.69969C14.7101 2.10557 15.5 4.01209 15.5 6C15.5 7.98791 14.7101 9.89443 13.3039 11.3003C13.0376 11.5665 12.6059 11.5666 12.3396 11.3005C12.0732 11.0343 12.0732 10.6028 12.3394 10.3366C13.4899 9.18635 14.1362 7.62647 14.1362 6C14.1362 4.37353 13.4899 2.81365 12.3394 1.66338C12.0732 1.39719 12.0732 0.965661 12.3396 0.699545ZM7.61423 0.614973C7.85048 0.728463 8.00073 0.967258 8.00073 1.22924V10.7708C8.00073 11.0327 7.85048 11.2715 7.61423 11.385C7.37798 11.4985 7.09756 11.4666 6.89287 11.3029L3.67023 8.72615H1.18188C0.80529 8.72615 0.5 8.42101 0.5 8.04461V3.95539C0.5 3.57899 0.80529 3.27385 1.18188 3.27385H3.67023L6.89287 0.697054C7.09756 0.533392 7.37798 0.501484 7.61423 0.614973ZM6.63696 2.64727L4.33539 4.48758C4.21448 4.58426 4.06426 4.63693 3.90942 4.63693H1.86377V7.36307H3.90942C4.06426 7.36307 4.21448 7.41574 4.33539 7.51242L6.63696 9.35273V2.64727ZM9.93251 3.10537C10.1988 2.83925 10.6306 2.83932 10.8968 3.10551C11.6638 3.87236 12.0947 4.91228 12.0947 5.99659C12.0947 7.08091 11.6638 8.12083 10.8968 8.88767C10.6306 9.15387 10.1988 9.15393 9.93251 8.88782C9.66617 8.6217 9.66611 8.19017 9.93236 7.92398C10.4437 7.41275 10.7309 6.71947 10.7309 5.99659C10.7309 5.27372 10.4437 4.58044 9.93236 4.06921C9.66611 3.80301 9.66617 3.37149 9.93251 3.10537Z" fill="black"/>
                                            </svg>
                                        Listen
                                    </div>
                                </div>
                            </div>
                            <!-- golf_partner -->
                            <!-- golf_partner_dtls -->
                            <div class="golf_partner_dtls">
                                <h5>
                                    <span>{{row.name}}</span>
                                    <div class="flag">
                                        {{row.locationEmoji}}
                                    </div>
                                </h5>
                                <p>{{row.personality}}</p>
                            </div>
                            <!-- golf_partner_dtls -->
                        </div>
                        <!-- golf_partner_box -->
                    </drag-scroll>
                }
                </div>
                <!-- chatArea -->
                <div class="chatArea">
                    <h5>Try Asking Golf.ai</h5>
                    <div class="chatBox">
                        <!-- chat -->
                        <div class="chat" (click)="setQuestion('Give me the recent news about Tiger.')">
                            <span>
                                <img src="assets/images/chatIcon4.svg" alt="" class="me-1">
                                Get the Latest from Your Partner
                            </span>
                            Give me the recent news about Tiger.
                        </div>
                        <!-- chat -->
                         <!-- chat -->
                        <div class="chat" (click)="setQuestion('What happens if an animal moves my ball?')">
                            <span>
                                <img src="assets/images/chatIcon3.svg" alt="" class="me-1">
                                Ask Your Partner About the Rules
                            </span>
                            What happens if an animal moves my ball?
                        </div>
                        <!-- chat -->                     
                        <!-- chat -->
                        <div class="chat" (click)="setQuestion('Who is better off the tee, Rory or Scottie?')">
                            <span>
                                <img src="assets/images/chatIcon1.svg" alt="" class="me-1">
                                Debate Your Partner
                            </span>
                            Who is better off the tee, Rory or Scottie?
                        </div>
                        <!-- chat -->
                        <div class="chat" (click)="setQuestion('Tell me the current tour results.')">
                            <span>
                                <img src="assets/images/chat-circle-dots.svg" width="15px" alt="" class="me-1">
                                Request Tournament Updates from Your Partner
                            </span>
                            Tell me the current tour results.
                        </div>
                        <!-- chat -->
                    </div>
                    <!-- chat -->
                </div>
                <!-- chat_and_fileUploadBg -->
            <div class="chat_and_fileUploadBg" [ngClass]="{'active' : showUploadModal}">
                <!-- chat_and_fileUpload -->
                <!-- <div class="fileUploadBox">
                        <div class="btns">
                            <img src="assets/images/imgIconGrn.svg" multiple  alt="" class="me-2">
                            Upload Image
                            <input type="file" (change)="onFileChange($event)" accept="image/*,video/*">
                        </div>
                        <div class="btns black" data-bs-toggle="modal" data-bs-target="#cameraModal">
                            Open Camera
                        </div>
                        <div class="btns green">
                            Upload Document
                            <input type="file">
                        </div>
                    </div> -->
                <div class="file_preview" *ngIf="files.controls.length">
                    <div class="file_preview__items" *ngFor="let file of files.controls; let i = index">
                        <ng-container *ngIf="isImage(file.get('type')?.value); else notImage">
                            <img [src]="file.get('preview')?.value" [title]="fileName" alt="File Preview"
                                class="file-img" />
                        </ng-container>
                        <ng-template #notImage>
                            <ng-container *ngIf="isVideo(file.get('type')?.value); else notVideo">
                                <video [src]="file.get('preview')?.value" [title]="fileName" controls
                                    class="file-video"></video>
                            </ng-container>
                            <ng-template #notVideo>
                                <ng-container *ngIf="isAudio(file.get('type')?.value); else notAudio">
                                    <audio [src]="file.get('preview')?.value" [title]="fileName" controls
                                        class="file-audio"></audio>
                                </ng-container>
                                <ng-template #notAudio>
                                    <div class="file-icon" [title]="fileName" style="font-size: 70px;">📄</div>
                                </ng-template>
                            </ng-template>
                        </ng-template>
                        <button type="button" class="btn remove" (click)="removeFile(i)">
                            <img src="assets/images/closeIcon.svg" alt="">
                        </button>
                    </div>
                </div>
                <!-- chat_and_fileUpload -->
                <div class="chat_and_fileUpload">
                    <div class="d-flex">
                        <!-- <div class="addBtn" (click)="openLoginModal()">
                                <img src="assets/images/plusFrame.svg" alt="">
                            </div> -->
                        <!-- <div class="addBtn" (click)="showUploadModal = !showUploadModal">
                                <img src="assets/images/plusFrame.svg" alt="">
                            </div> -->
                        <div class="position-relative">
                            <input type="text" [(ngModel)]="question" placeholder="Ask anything">
                            <!-- <img src="assets/images/microPhoneIcon.svg" alt=""> -->
                        </div>
                    </div>
                </div>
                <!-- chat_and_fileUpload -->
            </div>
            <!-- chat_and_fileUploadBg -->

            <!-- <a href="javascript:void(0)" (click)="sending ? '' : sendMessage()" class="common-btn w-100 mt-4">Get Started</a> -->
            <a href="javascript:void(0)" (click)="viewAnswer(question)" class="common-btn w-100 mt-4">Get Started</a>
            </div>
            <!-- chatArea -->

            
        </div>
        <div class="col-lg-6 d-flex flex-column">
            <div class="box order-md-2">
                <div class="d-flex justify-content-between box_header">
                    <div class="d-block">
                        <h4 class="me-1">Experience Golf Your Way</h4>
                        <p>Golf’s Top AI experience anywhere you go.</p>
                    </div>
                    <button class="smlBtn dark" (click)="openAuthModal('register')">Experience &nbsp;<a
                            href="javascript:void(0)">
                            <img src="assets/images/logoIcon.svg" height="22" width="22" />Golf.ai</a>
                    </button>
                </div>
                <div class="d-flex pt-3 experienceGolf-list" *ngIf="showGolfExperienceSlider">
                    <drag-scroll (reachesLeftBound)="endOfLeft = $event" (reachesRightBound)="endOfRight = $event" #nav>
                        <!-- smlBox -->
                        <div drag-scroll-item class="smlBox">
                            <h5>Worlds Top AI
                                Rules Official!</h5>
                            <div class="barBg">
                                <div class="col">
                                    <label>65%</label>
                                    <div class="d-inline-flex gray">
                                        ChatGPT
                                    </div>
                                </div>
                                <div class="col">
                                    <label>85%</label>
                                    <div class="d-inline-flex green">
                                        Golf.ai
                                    </div>
                                </div>
                            </div>
                            <p class="mt-2 text-center">Level 2 Accuracy</p>
                        </div>
                        <!-- smlBox -->
                        <!-- smlBox -->
                        <div drag-scroll-item class="smlBox">
                            <h5 class="mb-2">Top golf reporter</h5>
                            <p>Up to date information worldwide.</p>
                            <div class="d-block mt-4">
                                <table class="table table-striped table-borderless">
                                    <tbody>
                                        <tr>
                                            <td>Scottie Scheffler</td>
                                        </tr>
                                        <tr>
                                            <td>Tommy Fleetwood</td>
                                        </tr>
                                        <tr>
                                            <td>Justin Thomas</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- smlBox -->
                        <!-- smlBox -->
                        <div drag-scroll-item class="smlBox">
                            <h5 class="mb-2">Use data to improve your game</h5>
                            <p>Your swing data finally not going to waste.</p>
                            <div class="d-block mt-4">
                                <h4>+ 2.02</h4>
                                <p class="text-center">Swing Accuracy</p>
                            </div>
                        </div>
                        <!-- smlBox -->
                    </drag-scroll>
                </div>
            </div>
            <!-- grnBox -->
            <div class="grnBox" *ngIf="isBrowser">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="item" #carouselSlideItem>
                            <div class="d-block">
                                <span class="comingBtn">Coming Soon</span>
                                <h1>What's In My Bag</h1>
                                <!-- <h1>30,000 <span>+</span></h1> -->
                                <!-- <h6>Courses Worldwide</h6> -->
                            </div>
                            <div class="d-block mt-4">
                                <h5>Is Your Bag a Perfect Match?</h5>
                                <p>With our AI models, we analyse and map your equipment to your distances,
                                     strengths and weaknesses. Uncover any gaps that may exist including insights into cause and effect. <br> &nbsp; <br> </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item" #carouselSlideItem>
                            <div class="d-block">
                                <span class="comingBtn">Coming Soon</span>
                                <h1>Ai Concierge Services</h1>
                                <!-- <h1>30,000 <span>+</span></h1> -->
                                <!-- <h6>Courses Worldwide</h6> -->
                            </div>
                            <div class="d-block mt-4">
                                <h5>Your Personal Golf Travel Planner</h5>
                                <p>
                                    Access preferred tee times, fully guided tours through to all inclusive packages. Explore trending 
                                    destinations and courses that match your preferences, skill level and budget—all in one place.
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item" #carouselSlideItem>
                            <div class="d-block">
                                <span class="comingBtn">Coming Soon</span>
                                <h1>30,000+ Courses</h1>
                                <!-- <h1>30,000 <span>+</span></h1> -->
                                <!-- <h6>Courses Worldwide</h6> -->
                            </div>
                            <div class="d-block mt-4">
                                <h5>Your On-Course Ai Caddy</h5>
                                <p>
                                    Get real-time help with your strategy, course management and club selection. 
                                    Our AI even assesses real time weather conditions in conjunction with your validated distances
                                     to give you the right advice when you need it. Stay in bounds and lower your scores.
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item" #carouselSlideItem>
                            <div class="d-block">
                                <span class="comingBtn">Coming Soon</span>
                                <h1>PGA Tour Ai Predictions</h1>
                                <!-- <h1>30,000 <span>+</span></h1> -->
                                <!-- <h6>Courses Worldwide</h6> -->
                            </div>
                            <div class="d-block mt-4">
                                <h5>Your Weekly Form Guide</h5>
                                <p>
                                    Using advanced Ai models, we help you leverage available data to navigate the golf betting markets, 
                                    offering insights into how a 
                                    player might feature at an upcoming tournament based on recent history. (where legal)
                                </p>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!-- grnBox -->
    
            <!-- hasBg -->
            <div class="box order-3 hasBg">
                <div class="d-flex justify-content-between box_header">
                    <div class="d-block">
                        <h4 class="me-1">Ai Rules Assistant</h4>
                        <p>Get the definitive answer to a unusual situation.</p>
                    </div>
                    <a (click)="openAuthModal('register')" class="smlBtn dark">Ask any Question, Get The Ruling</a>
                </div>
                <div class="d-flex pt-3 rule-list" *ngIf="showAIRules">
                    <drag-scroll (reachesLeftBound)="endOfLeft = $event" (reachesRightBound)="endOfRight = $event" #nav>
                        <!-- smlBox -->
                        <div drag-scroll-item class="smlBox d-flex flex-column justify-content-between bgtransparent">
                            <h5>What happens if there is a dangerous animal on the course?</h5>
                            <div class="viewAnswer">
                                <a href="javascript:void(0)"
                                    (click)="viewAnswer('What happens if there is a dangerous animal on the course?')"
                                    class="viewAnswer_btn">
                                    View answer
                                    <img src="assets/images/linkIcon.svg" alt="">
                                </a>
                            </div>
                        </div>
                        <!-- smlBox -->
                        <!-- smlBox -->
                        <div drag-scroll-item class="smlBox d-flex flex-column justify-content-between bgtransparent">
                            <h5>Can I declare a ball unplayable anywhere, even in a bunker?</h5>
                            <div class="viewAnswer">
                                <a href="javascript:void(0)"
                                    (click)="viewAnswer('Can I declare a ball unplayable anywhere, even in a bunker?')"
                                    class="viewAnswer_btn">
                                    View answer
                                    <img src="assets/images/linkIcon.svg" alt="">
                                </a>
                            </div>
                        </div>
                        <!-- smlBox -->
                        <!-- smlBox -->
                        <div drag-scroll-item class="smlBox d-flex flex-column justify-content-between bgtransparent">
                            <h5>Can I move natural obstructions like sticks and leaves?</h5>
                            <div class="viewAnswer">
                                <a href="javascript:void(0)"
                                    (click)="viewAnswer('Can I move natural obstructions like sticks and leaves?')"
                                    class="viewAnswer_btn">
                                    View answer
                                    <img src="assets/images/linkIcon.svg" alt="">
                                </a>
                            </div>
                        </div>
                        <!-- smlBox -->
                    </drag-scroll>
                </div>
            </div>
            <!-- hasBg -->
            
        </div>
    </div>
    
<!-- home__Cont -->