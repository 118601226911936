import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, throwError } from "rxjs";
import { ToasterService } from "../shared/services/toaster.service";
import { TokenService } from "../auth/token.service";
import { isPlatformBrowser } from "@angular/common";
import {PLATFORM_ID} from '@angular/core';

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const toaster = inject(ToasterService);
  const tokenService = inject(TokenService);
  const platformId = inject(PLATFORM_ID);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMessage = '';

      if (isPlatformBrowser(platformId) && error.error instanceof ErrorEvent) {
        // Client-side errors
        errorMessage = `${error.error.message}`;
      } else if(isPlatformBrowser(platformId)) {
        // Server-side errors
        errorMessage = `${error.status}: ${error.error.message}`;

        // Handle specific HTTP status codes
        if (error.status === 401) {
          // Unauthorized error, redirect to login
          tokenService.removeTokens();
          router.navigate(['/']);
          // errorMessage = 'Unauthorized access. Redirecting to login.';
        } else if (error.status === 403) {
          errorMessage = 'Forbidden: Access denied.';
        } else if (error.status === 404) {
          errorMessage = 'Resource not found.';
        }
      }

      // Display error in the toaster, regardless of platform
      if (errorMessage && isPlatformBrowser(platformId)) {
        toaster.error(errorMessage,"Error");
      }

      // Return an observable with a user-facing error message
      return throwError(() => new Error(errorMessage));
    })
  );
};
