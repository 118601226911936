<div class="login-body">
    <div class="signUpbox" [class.choosePartner]="page === 'language-caddy'">
        <div class="logoSec">
            <img src="assets/images/logo.svg" alt="">
            <div *ngIf="page !== 'email-verification'" class="close-btn" (click)="close()">
                &times;
            </div>
        </div>
<!-------------------------------------------------------------------Log in ------------------------------------->
        @if (page === 'login') {
            <div class="title">
                Login to your <br>account
            </div>
            <h6>Login to your Golf.ai account</h6>
            <div class="btnBlock">
                <a *ngIf="!inEmbeddedBrowser" href="javascript:void(0)" (click)="socialMediaLogin('google')" class="btn">
                    <img src="assets/images/googleLogo.svg" alt="">Continue with Google
                </a>
                <a href="javascript:void(0)" (click)="socialMediaLogin('apple')" class="btn black">
                    <img src="assets/images/appleLogo.svg" alt="">Continue with Apple
                </a>
            </div>
            <div class="orBg">
                <span>Or</span>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="login()">
                <div class="mb-3">
                    <input type="email" id="email" formControlName="email" class="form-control" placeholder="Email Address">
                    <div *ngIf="loginForm.get('email')?.invalid && (loginForm.get('email')?.touched || formSubmitted)"
                        class="text-danger">
                        <div *ngIf="loginForm.get('email')?.errors?.['required']">Email is required</div>
                        <div *ngIf="loginForm.get('email')?.errors?.['email']">Invalid email address</div>
                    </div>
                </div>
                <div class="mb-3 pswdField">
                    <div class="d-block">
                        <input [type]="showPassword ? 'text': 'password'" id="password" formControlName="password"
                            class="form-control" placeholder="Password">
                        <img *ngIf="!showPassword" src="assets/images/eye-open.svg" alt="" (click)="showPassword = true">
                        <img *ngIf="showPassword" class="eye-closed-svg" src="assets/images/eye_closed.svg" alt=""
                            (click)="showPassword = false">
                    </div>
                    <div *ngIf="loginForm.get('password')?.invalid && (loginForm.get('password')?.touched || formSubmitted)"
                        class="text-danger">
                        <div *ngIf="loginForm.get('password')?.errors?.['required']">Password is required</div>
                    </div>
                </div>
                <button type="submit" class="common-btn">
                    <img *ngIf="submitting" class="btn-img" src="/assets/images/loading-btn.svg" />
                    <ng-container *ngIf="!submitting">
                        Login
                    </ng-container>
                </button>
            </form>
            <h6 class="mt-4"><a (click)="page = 'forgot-password'">Forgot your password?</a></h6>
            <a (click)="page = 'register'" class="blackBtn mt-4">Don’t have an account? <b>Signup</b></a>
        } @else if(page === 'register'){
<!------------------------------------------------------------------- Signup ------------------------------------->
            <div class="title">
                Create your <br>free account
            </div>
            <h6>No Credit card required, Get instant access.</h6>
            <div class="btnBlock">
                <a *ngIf="!inEmbeddedBrowser" href="javascript:void(0)" (click)="socialMediaLogin('google')" class="btn">
                    <img src="assets/images/googleLogo.svg" alt="">Continue with Google
                </a>
                <a href="javascript:void(0)" (click)="socialMediaLogin('apple')" class="btn black">
                    <img src="assets/images/appleLogo.svg" alt="">Continue with Apple
                </a>
            </div>
            <div class="orBg">
                <span>Or</span>
            </div>
            <form [formGroup]="signupForm" (ngSubmit)="signup()" class="placeholderCenter">
                <div class="mb-4">
                    <input type="text" formControlName="name" class="form-control" placeholder="Name">
                    <div *ngIf="signupForm.get('name')?.invalid && signupForm.get('name')?.touched" class="text-danger">
                        <div *ngIf="signupForm.get('name')?.errors?.['required']">Name is required</div>
                    </div>
                </div>
                <div class="mb-3">
                    <input type="email" id="email" formControlName="email" class="form-control"
                        placeholder="Email Address">
                    <div *ngIf="signupForm.get('email')?.invalid && (signupForm.get('email')?.touched || signupSubmitted)"
                        class="text-danger">
                        <div *ngIf="signupForm.get('email')?.errors?.['required']">Email is required</div>
                        <div *ngIf="signupForm.get('email')?.errors?.['email']">Invalid email address</div>
                    </div>
                </div>
                <div class="mb-3 pswdField">
                    <div class="d-block">
                        <input [type]="showPassword?'text': 'password'" formControlName="password" (keyup)="passwordCheck()"
                            class="form-control" placeholder="Password">
                            <img *ngIf="!showPassword" src="assets/images/eye-open.svg" alt="" (click)="showPassword = true">
                            <img *ngIf="showPassword" class="eye-closed-svg" src="assets/images/eye_closed.svg" alt="" (click)="showPassword = false">
                    </div>
                    <div *ngIf="(signupForm.get('password')?.invalid || error) && (signupForm.get('password')?.touched || signupSubmitted)"
                        class="text-danger">
                        <div *ngIf="signupForm.get('password')?.errors?.['required']">Password is required</div>
                        <div *ngIf="signupForm.get('password')?.errors?.['minlength'] && !error">Password must
                            be at least 6 characters long</div>
                        <div *ngIf="error">{{error}}</div>
                    </div>
                </div>
                <!-- <div class="mb-3 pswdField">
                    <div class="d-block">
                        <input [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword" class="form-control"
                            placeholder="Confirm Password">
                            <img *ngIf="!showConfirmPassword" src="assets/images/eye-open.svg" alt="" (click)="showConfirmPassword = true">
                            <img *ngIf="showConfirmPassword" class="eye-closed-svg" src="assets/images/eye_closed.svg" alt="" (click)="showConfirmPassword = false">
                    </div>
                    <div *ngIf="signupForm.get('confirmPassword')?.invalid && (signupForm.get('confirmPassword')?.touched  || signupSubmitted)"
                        class="text-danger">
                        <div *ngIf="signupForm.get('confirmPassword')?.errors?.['required']">Confirm Password is
                            required</div>
                        <div *ngIf="signupForm.get('confirmPassword')?.errors?.['mustMatch']">Passwords must
                            match</div>
                    </div>
                </div> -->
                <button class="common-btn" type="submit" >
                    <img *ngIf="submitting" class="btn-img" src="/assets/images/loading-btn.svg" />
                    <ng-container *ngIf="!submitting">
                        Continue
                    </ng-container>
                </button>
            </form>
            <p class="mt-4">By continuing, you agree to Golf.ai’s 
                <a (click)="visitPage('/terms-of-service')">Terms & Conditions</a> and 
                <a (click)="visitPage('/privacy-policy')">Privacy Policy</a></p>
               <a (click)="page = 'login'" class="blackBtn mt-4">Already have an account? <b>Login</b></a>
        } @else if(page === 'email-verification'){
<!------------------------------------------------------------------- Email Verification ------------------------------------->
            <div class="title">
                Confirm Email
            </div>
            <h6 class="mb-4">A code has been sent to your email, copy the code into the box below to confirm your email address.</h6>
            <form action="">
                <div class="mb-3">
                    <div class="d-block">
                        <input type="text" class="form-control" 
                          [ngModelOptions]="{ standalone: true }" placeholder="Code" [(ngModel)]="verificationCode" >
                    </div>
                    @if (this.verificationSubmitted && !verificationCode){
                      <div class="text-danger">
                            Verification code is required.
                      </div>
                    }
                </div>
                <button (click)="confirmEmail()" class="common-btn">
                    <img *ngIf="submitting" class="btn-img" src="/assets/images/loading-btn.svg" />
                    <ng-container *ngIf="!submitting">
                        Continue
                    </ng-container>
                </button>
            </form>
        } @else if(page === 'language-caddy'){
<!------------------------------------------------------------------- Language and Caddy ------------------------------------->
            <div class="d-block mb-5">
                <h5>Choose Your Partner</h5>
                <!-- partnerCarousel -->
                <div class="partner-scroll">
                    @if (caddyLoading) {
                        <div class="item">
                            <div class="row">
                                <div class="col-6" *ngFor="let caddy of caddyLoadingArray">
                                    <div class="golf_partner_box loadingItem">
                                        <div class="golf_partner">
                                            <div class="imgBlock">
                                                <i class="w-100 h-100">ssssss</i>
                                            </div>
                                        </div>
                                        <div class="golf_partner_dtls">
                                            <h5>
                                                <span><i>xxxxxxxxxxxxxx</i></span>
                                            </h5>
                                            <p><i>sssssss</i></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } @else {
                        <owl-carousel-o [options]="customOptions">
                            <ng-template carouselSlide *ngFor="let page of caddyPages">
                                <div class="item">
                                    <div class="row">
                                      <div class="col-6" *ngFor="let caddy of page; trackBy: trackByIndex">
                                        <!-- golf_partner_box -->
                                        <div class="golf_partner_box" 
                                          [class.select]="selectedCaddy?.id === caddy.id" 
                                          (click)="selectCaddy(caddy)">
                                          <!-- golf_partner -->
                                          <div class="golf_partner">
                                            <div class="imgBlock">
                                              <img [src]="caddy.imageUrl" alt="">
                                            </div>
                                          </div>
                                          <!-- golf_partner -->
                                          <!-- golf_partner_dtls -->
                                          <div class="golf_partner_dtls">
                                            <h5>
                                              <span>{{caddy.name}}</span>
                                              <div class="flag">
                                                {{caddy.locationEmoji}}
                                              </div>
                                              <a>
                                                <img src="assets/images/speakerIconGrn.png" alt="">
                                              </a>
                                            </h5>
                                            <p>{{caddy.personality}}</p>
                                          </div>
                                          <!-- golf_partner_dtls -->
                                        </div>
                                        <!-- golf_partner_box -->
                                      </div>
                                    </div>
                                  </div>
                            </ng-template>
                        </owl-carousel-o>
                    }
                  </div>
                  
                <!-- partnerCarousel -->
            </div>

            <div class="d-block">
                <h5>Choose Your Language</h5>
                <!-- chooseLangBlock -->
                @if (caddyLoading) {
                    <div class="loadingItem language-load">
                        <i>sssssss</i>
                    </div>
                } @else {
                    <div class="chooseLangBlock" [class.active]="expandLanguages">
                        <!-- langListBox -->
                        <div class="langListBox">
                            <ul>
                                @for (language of languageList; track $index) {
                                    <li (click)="selectedLanguage = language" [class.active]="selectedLanguage.id === language.id">
                                        <img src="{{language.imageUrl}}" alt="{{language.name}}">
                                        <span>{{language.name}}, {{language.code}}</span>
                                    </li>
                                }
                            </ul>
                        </div>
                        <!-- langListBox -->
                        <!-- langBox -->
                        <div class="langBox" *ngIf="selectedLanguage" (click)="expandLanguages = !expandLanguages">
                            <div class="d-flex">
                                <img src="{{selectedLanguage.imageUrl}}" alt="">
                                <span>{{selectedLanguage.name}}, {{selectedLanguage.code}}</span>
                            </div>
                            <img src="assets/images/arwBlack.svg" alt="" class="arwBlack">
                        </div>
                        <!-- langBox -->
                    </div>
                    <!-- chooseLangBlock -->
                }
                
            </div>
            <a *ngIf="!caddyLoading" (click)="submitCaddyLanguage()" class="common-btn">
                <img *ngIf="submitting" class="btn-img" src="/assets/images/loading-btn.svg" />
                    <ng-container *ngIf="!submitting">
                        Continue
                    </ng-container>
            </a>
        } @else if(page === 'forgot-password'){
            <div class="title">Reset your password</div>
            <h6 class="mb-4">Enter your email address and we will send you instructions to reset your password.</h6>
            <form [formGroup]="forgotForm" (ngSubmit)="forgotPassword()">
                <div class="mb-3">
                    <div class="d-block">
                        <input type="text" class="form-control" formControlName="email" placeholder="Peter@example.com">
                    </div>
                    @if (forgotForm.get('email')?.invalid && forgotForm.get('email')?.touched){
                        <div *ngIf="forgotForm.get('email')?.errors?.['required']" class="text-danger">Email is required</div>
                        <div *ngIf="forgotForm.get('email')?.errors?.['email']" class="text-danger">Invalid email address</div>
                    }
                </div>
                <button class="common-btn">
                    <img *ngIf="submitting" class="btn-img" src="/assets/images/loading-btn.svg" />
                    <ng-container *ngIf="!submitting">
                        Continue
                    </ng-container>
                </button>
            </form>
        } @else if(page === 'reset-password'){
            <div class="title">Reset password</div>
            <h6 class="mb-4">Please enter the verification code that was sent to your email to reset your password.</h6>
            <form [formGroup]="resetForm" (ngSubmit)="submitResetForm()">
                <div class="mb-4">
                    <input type="text" formControlName="verificationCode" class="form-control" placeholder="Verification Code">
                    <div *ngIf="(resetForm.get('verificationCode')?.invalid) && formSubmitted" class="text-danger">
                        <div *ngIf="resetForm.get('verificationCode')?.errors?.['required']">Verification code is required</div>
                    </div>
                </div>
                <div class="mb-4 pswdField">
                    <input [type]="showResetPassword ? 'text': 'password'" formControlName="newPassword" (keyup)="passwordCheck()" class="form-control" placeholder="Password">
                    <img *ngIf="!showResetPassword" src="assets/images/eye-open.svg" alt="" (click)="showResetPassword = true">
                    <img *ngIf="showResetPassword" class="eye-closed-svg" src="assets/images/eye_closed.svg" alt=""
                            (click)="showResetPassword = false">
                    <div *ngIf="(resetForm.get('newPassword')?.invalid || error) && formSubmitted" class="text-danger">
                        <div *ngIf="resetForm.get('newPassword')?.errors?.['required']">Password is required</div>
                        <div *ngIf="resetForm.get('newPassword')?.errors?.['minlength'] && !error">Password must be at least 6 characters long</div>
                        <div *ngIf="error">{{error}}</div>
                    </div>
                </div>
                <div class="mb-4 pswdField">
                    <input [type]="showConfirmPassword ? 'text': 'password'" formControlName="confirmPassword" class="form-control" placeholder="Confirm Password">
                    <img *ngIf="!showConfirmPassword" src="assets/images/eye-open.svg" alt="" (click)="showConfirmPassword = true">
                    <img *ngIf="showConfirmPassword" class="eye-closed-svg" src="assets/images/eye_closed.svg" alt=""
                            (click)="showConfirmPassword = false">
                    <div *ngIf="resetForm.get('confirmPassword')?.invalid && formSubmitted" class="text-danger">
                        <div *ngIf="resetForm.get('confirmPassword')?.errors?.['required']">Confirm Password is required</div>
                        <div *ngIf="resetForm.get('confirmPassword')?.errors?.['mustMatch']">Passwords must match</div>
                    </div>
                </div>
                <button class="btn common-btn" type="submit">
                    <img *ngIf="submitting" class="btn-img" src="/assets/images/loading-btn.svg"/>
                    <ng-container *ngIf="!submitting">
                        Submit
                    </ng-container>
                </button>
            </form>
        }
    </div>
    <!-- sign in -->
</div>