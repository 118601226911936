import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet, RouterModule } from '@angular/router';

@Component({
  selector: 'app-golf-digest-layout',
  standalone: true,
  imports: [RouterOutlet, RouterModule, CommonModule],
  templateUrl: './golf-digest-layout.component.html',
  styleUrl: './golf-digest-layout.component.scss'
})
export class GolfDigestLayoutComponent {

}
