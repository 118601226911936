<div class="container">
    <!-- topMenu -->
    <div class="topMenu">
        <div class="logoBg">
            <a href="">
                <img src="assets/images/logoIcon.svg" alt="Golf AI" class="d-none d-md-block">
                <img src="assets/images/mblLogo.svg" alt="Golf AI" class="d-md-none">
            </a>
        </div>
        <div class="mainLogo d-none d-md-block">
            <img src="assets/images/logo.svg" alt="Golf AI">
        </div>
        <div class="d-inline-flex">
            <a (click)="openAuthModal(authModal, 'login')" class="smlBtn d-md-block">Login</a>
            <a (click)="openAuthModal(authModal, 'register')" class="smlBtn dark d-none d-md-block">Join For Free</a>
            <a (click)="openAuthModal(authModal, 'register')" class="smlBtn dark d-md-none">Join</a>
        </div>
    </div>
    <!-- topMenu -->
    <!-- home__Cont -->
    <router-outlet></router-outlet>
    <!-- home__Cont -->
    <!-- footer -->
    <div class="footer">
        <div class="d-flex left">
            <img src="assets/images/logoBlack.svg" alt="">
            <ul>
                <li>
                    <a target="_blank" href="http://linkedin.com/company/golf-ai"><img
                            src="assets/images/linkedin-logo.svg" alt=""></a>
                </li>
                <!-- <li>
                    <a target="_blank" href="https://www.instagram.com/golfai_app/">
                        <img src="assets/images/instagramIcon.svg" alt="">
                    </a>
                </li> -->
            </ul>
        </div>
        <div class="right d-flex flex-column">
            <ul class="order-md-2">
                <li>
                    <!-- <a routerLink="/privacy-policy">Privacy Policy</a> -->
                    <a href="/assets/files/Golf.ai-Privacy-Policy.pdf" target="_blank">Privacy Policy</a>
                </li>
                <li>
                    <!-- <a routerLink="/terms-of-service">Terms & Conditions</a> -->
                    <a href="/assets/files/Golf.ai-Terms-of-Service.pdf" target="_blank">Terms & Conditions</a>
                </li>
                <li>
                    <a href="mailto:contact@golf.ai">{{'support@golf.ai'}}</a>
                </li>
            </ul>
            <p>Copyright © {{thisYear}} Golf.ai All rights reserved</p>
        </div>
    </div>
    <!-- footer -->


<ng-template #authModal>
    <app-auth-modal 
      [inEmbeddedBrowser]="inEmbeddedBrowser" 
      [page]="authPage" 
      [loadCaddy]="loadCaddyData" 
      (closeModal)="closeAuthModal($event)">
    </app-auth-modal>
</ng-template>